import { useQuery } from 'react-query';
import { metricsQueries, useMetricsService } from '../../../../services/metricsService';
import { useEffect, useState } from 'react';

export function useCardReportProdutivityHook() {
    const assetService = useMetricsService();
    const [ACTLineChart, setACTLineChart] = useState();
    const [ACTBarYears, setACTBarYears] = useState();
    const [ACTBarChart, setACTBarChart] = useState();
    const [APPLineChart, setAPPLineChart] = useState();
    const [APPBarChart, setAPPBarChart] = useState();
    const [SCMLineChart, setSCMLineChart] = useState();
    const [AAPLineChart, setAAPLineChart] = useState();
    const [AAPBarYears, setAAPBarYears] = useState();

    const monthsNames = ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'];

    const {
        data: metricsData,
        isLoading: isLoadingMetrics,
        isRefetching: isRefetchingMetrics,
        refetch: refetchAssets
    } = useQuery(metricsQueries.GET_PRODUTIVITY_BY_CLIENT, () => assetService.getProductivityMetricsById({

        // TODO Colocar dinamico
        fkClientId: 1
    }));

    useEffect(() => {

        const categories = metricsData?.longTermAnalysisOfAntiCorrosionTreatment?.barYears?.map(item => item.label) || [];
        const dataValues = metricsData?.longTermAnalysisOfAntiCorrosionTreatment?.barYears?.map(item => parseFloat(item.values[1] / 100).toFixed(2)) || [];
        const secondDataValues = metricsData?.longTermAnalysisOfAntiCorrosionTreatment?.barYears?.map(item => parseFloat(item.values[0] / 100).toFixed(2)) || [];

        const totalDataValues = [];
        metricsData?.longTermAnalysisOfAntiCorrosionTreatment?.barYears?.forEach(item => {
            totalDataValues.push(parseFloat(item.values[0] / 100).toFixed(2));
            totalDataValues.push(parseFloat(item.values[1] / 100).toFixed(2));
        });

        const maxValue = Math.max(...totalDataValues);
        const minValue = Math.min(...totalDataValues);

        setACTBarYears({
            categories,
            series: [{
                name: "Planejado",
                color: "#0c8ce9",
                data: dataValues,
            },
            {
                name: "Realizado",
                color: '#ff5e19',
                data: secondDataValues,
            }],
            maxValue,
            minValue
        });
    }, [metricsData]);

    useEffect(() => {

        const categories = [''];
        const accumulatedSheduled = [parseFloat(metricsData?.longTermAnalysisOfAntiCorrosionTreatment?.barChart?.accumulatedSheduled / 100).toFixed(2)];
        const accumulatedRealized = [parseFloat(metricsData?.longTermAnalysisOfAntiCorrosionTreatment?.barChart?.accumulatedRealized / 100).toFixed(2)];
        const budget = [parseFloat(metricsData?.longTermAnalysisOfAntiCorrosionTreatment?.barChart?.budget / 100).toFixed(2)];


        const totalDataValues = [accumulatedSheduled, accumulatedRealized, budget];

        const maxValue = Math.max(...totalDataValues);
        const minValue = Math.min(...totalDataValues);

        setACTBarChart({
            categories,
            series: [
                {
                    name: 'Acum. Programado',
                    color: "#0c8ce9",
                    data: accumulatedRealized
                },
                {
                    name: 'Acum. Realizado',
                    color: "#ff4c00",
                    data: accumulatedSheduled
                },

                {
                    name: 'Orçamento',
                    color: "#9a9a99",
                    data: budget,
                }
            ],
            maxValue,
            minValue
        });

    }, [metricsData]);

    useEffect(() => {
        const categories = metricsData?.longTermAnalysisOfAntiCorrosionTreatment?.lineChart?.map(item => monthsNames[item.label]) || [];
        const dataValues = metricsData?.longTermAnalysisOfAntiCorrosionTreatment?.lineChart?.map(item => parseFloat(item.values[0] / 100).toFixed(2)) || [];
        const secondDataValues = metricsData?.longTermAnalysisOfAntiCorrosionTreatment?.lineChart?.map(item => parseFloat(item.values[1] / 100).toFixed(2)) || [];

        const totalDataValues = [];
        metricsData?.longTermAnalysisOfAntiCorrosionTreatment?.lineChart?.forEach(item => {
            totalDataValues.push(parseFloat(item.values[0] / 100).toFixed(2));
            totalDataValues.push(parseFloat(item.values[1] / 100).toFixed(2));
        });

        const maxValue = Math.max(...totalDataValues);
        const minValue = Math.min(...totalDataValues);

        setACTLineChart({
            categories,
            series: [{
                name: "Execução de TAC",
                color: "#ff0000",
                data: dataValues
            },
            {
                name: "Orçamento",
                color: '#878787',
                data: secondDataValues,
                dashed: true
            }],
            maxValue,
            minValue
        });
    }, [metricsData]);


    useEffect(() => {
        const categories = metricsData?.annualPaintingPlanMetrics?.lineChart.map(item => monthsNames[item.label]) || [];
        const dataValues = metricsData?.annualPaintingPlanMetrics?.lineChart.map(item => parseFloat(item.values[0] / 100).toFixed(2)) || [];
        const secondDataValues = metricsData?.annualPaintingPlanMetrics?.lineChart.map(item => parseFloat(item.values[1] / 100).toFixed(2)) || [];
        const terciaryDataValues = metricsData?.annualPaintingPlanMetrics?.lineChart.map(item => parseFloat(item.values[2] / 100).toFixed(2)) || [];

        const totalDataValues = [];
        metricsData?.annualPaintingPlanMetrics?.lineChart.forEach(item => {
            totalDataValues.push(parseFloat(item.values[0] / 100).toFixed(2));
            totalDataValues.push(parseFloat(item.values[1] / 100).toFixed(2));
            totalDataValues.push(parseFloat(item.values[2] / 100).toFixed(2));
        });

        const maxValue = Math.max(...totalDataValues);
        const minValue = Math.min(...totalDataValues);

        setAPPLineChart({
            categories,
            series: [{
                name: "Priorização Técnica",
                color: "#0c8ce9",
                data: dataValues
            },
            {
                name: "Execução de TAC",
                color: '#ff4c00',
                data: secondDataValues,
            },
            {
                name: "Orçamento",
                color: '#878787',
                data: terciaryDataValues,
                dashed: true
            },

            ],
            maxValue,
            minValue
        });
    }, [metricsData]);

    useEffect(() => {

        const categories = [''];
        const accumulatedSheduled = [parseFloat(metricsData?.annualPaintingPlanMetrics?.barChart?.accumulatedSheduled / 100).toFixed(2)];
        const accumulatedRealized = [parseFloat(metricsData?.annualPaintingPlanMetrics?.barChart?.accumulatedRealized / 100).toFixed(2)];
        const budget = [parseFloat(metricsData?.annualPaintingPlanMetrics?.barChart?.budget / 100).toFixed(2)];


        const totalDataValues = [accumulatedSheduled, accumulatedRealized, budget];

        const maxValue = Math.max(...totalDataValues);
        const minValue = Math.min(...totalDataValues);

        setAPPBarChart({
            categories,
            series: [
                {
                    name: 'Acum. Programado',
                    color: "#0c8ce9",
                    data: accumulatedRealized
                },
                {
                    name: 'Acum. Realizado',
                    color: "#ff4c00",
                    data: accumulatedSheduled
                },
                {
                    name: 'Orçamento',
                    color: "#9a9a99",
                    data: budget,
                }
            ],
            maxValue,
            minValue
        });

    }, [metricsData]);

    useEffect(() => {
        const categories = metricsData?.sCurveMetrics?.lineChart?.map(item => monthsNames[item.label]) || [];
        const dataValues = metricsData?.sCurveMetrics?.lineChart?.map(item => parseFloat(item.values[0] / 100).toFixed(0)) || [];
        const secondDataValues = metricsData?.sCurveMetrics?.lineChart?.map(item => parseFloat(item.values[1] / 100).toFixed(0)) || [];

        const totalDataValues = [];
        metricsData?.sCurveMetrics?.lineChart?.forEach(item => {
            totalDataValues.push(parseFloat(item.values[0] / 100).toFixed(2));
            totalDataValues.push(parseFloat(item.values[1] / 100).toFixed(2));
        });

        const maxValue = Math.max(...totalDataValues);
        const minValue = Math.min(...totalDataValues);

        setSCMLineChart({
            categories,
            series: [{
                name: "Planejado",
                color: "#0c8ce9",
                data: dataValues
            },
            {
                name: "Realizado",
                color: '#ff4c00',
                data: secondDataValues,
            },

            ],
            maxValue,
            minValue
        });
    }, [metricsData]);

    useEffect(() => {
        const categories = metricsData?.adherenceToTheAnnualPaintingPlanMetrics?.lineChart?.map(item => monthsNames[item.label]) || [];
        const dataValues = metricsData?.adherenceToTheAnnualPaintingPlanMetrics?.lineChart?.map(item => parseFloat(item.values[0] / 100).toFixed(0)) || [];
        const totalDataValues = [];
        metricsData?.adherenceToTheAnnualPaintingPlanMetrics?.lineChart?.forEach(item => {
            totalDataValues.push(parseFloat(item.values[0] / 100).toFixed(2));
        });

        const maxValue = Math.max(...totalDataValues);
        const minValue = Math.min(...totalDataValues);

        setAAPLineChart({
            categories,
            series: [{
                name: "Ad - Real / Plan",
                color: "#ff4c00",
                data: dataValues
            },
            ],
            maxValue,
            minValue
        });
    }, [metricsData]);

    useEffect(() => {
        const categories = metricsData?.adherenceToTheAnnualPaintingPlanMetrics?.barYears?.map(item => item.label) || [];
        const dataValues = metricsData?.adherenceToTheAnnualPaintingPlanMetrics?.barYears?.map(item => parseFloat(item.values[1] / 100).toFixed(2)) || [];
        const secondDataValues = metricsData?.adherenceToTheAnnualPaintingPlanMetrics?.barYears?.map(item => parseFloat(item.values[0] / 100).toFixed(2)) || [];

        const totalDataValues = [];
        metricsData?.adherenceToTheAnnualPaintingPlanMetrics?.barYears?.forEach(item => {
            totalDataValues.push(parseFloat(item.values[0] / 100).toFixed(2));
            totalDataValues.push(parseFloat(item.values[1] / 100).toFixed(2));
        });

        const maxValue = Math.max(...totalDataValues);
        const minValue = Math.min(...totalDataValues);

        setAAPBarYears({
            categories,
            series: [{
                name: "Planejado",
                color: "#0c8ce9",
                data: dataValues,
            },
            {
                name: "Realizado",
                color: '#ff5e19',
                data: secondDataValues,
            }],
            maxValue,
            minValue
        });
    }, [metricsData]);



    return {
        produtivity: {
            ACTLineChart,
            ACTBarYears,
            ACTBarChart,
            APPLineChart,
            APPBarChart,
            SCMLineChart,
            AAPLineChart,
            AAPBarYears,
            metricsData,
            isLoading: isLoadingMetrics || isRefetchingMetrics,
            refetchAssets
        }
    }
}