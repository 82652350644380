import { responseStatus, useHandleRequest } from "../service/api-services";
import { getDefaultQuery } from "../utils/query-client";

export const utilsQueries = {
    EDIT: "UTILS_EDIT",
    CREATE: "UTILS_CREATE",
    DELETE: "UTILS_DELETE",
    GET_ALL: "UTILS_GET_ALL",
    GET_BY_ID: "UTILS_GET_BY_ID",
}


export function useUtilsService() {

    const handleRequest = useHandleRequest();

    async function uploadZip({ file, onUploadProgress, onSuccess, onError}) {

        const formData = new FormData();
        formData.append('file', file);
       
        const { status, data } = await handleRequest.handleRequest(`/utils/upload-zip`, 'POST', formData, undefined, onUploadProgress);
        if (status === responseStatus.SUCCESS) {
            if (onSuccess) {
                onSuccess(data);
                
            }
            return;
        }
      
        onError('Ocorreu um erro inesperado. Tente novamente.');
    }

    async function uploadSheets({ planilha, zip, onUploadProgress, onSuccess }) {

        const formData = new FormData();
        formData.append('planilha', planilha);
        formData.append('zip', zip);

        const { status, data } = await handleRequest.handleRequest(`/utils/upload-sheets`, 'POST', formData, undefined, onUploadProgress, 1000000);

        if (status === responseStatus.SUCCESS) {
            if (onSuccess) {
                onSuccess(data);
            }
        }
    }


    return {
        uploadZip,
        uploadSheets
    }
}
