import { useMutation } from "react-query";
import { useRef, useState } from "react";
import { queryClient } from "../../../../utils/query-client";
import { assets3dItemQueries, useAssets3DItemService } from "../../../../services/asset3DItemService";
import { useUtilsService } from "../../../../services/utilService";

export function useBulkSection3DModalHook({ onClose }) {
    const utilsService = useUtilsService();
    const [uploadProgress, setUploadProgress] = useState(0);
    const [errorMessage, setErrorMessage] = useState('');
    const fileInputRef = useRef(null);

    const { mutate, isLoading } = useMutation(
        (file) => utilsService.uploadZip({ file, onUploadProgress: handleUploadProgress, onSuccess, onError }),
    );

    function onSuccess() {
        queryClient.refetchQueries(assets3dItemQueries.GET_ALL);
        resetFileInput(); // Limpa o input após o sucesso
        onClose();
    }

    function onError(message) {
        resetFileInput(); // Limpa o input em caso de erro
        setErrorMessage(message);
    }

     // Função para limpar o campo de input
     function resetFileInput() {
            document.getElementById('zip-file').value = ""; // Reseta o valor do input file
    }

    function handleUploadProgress(progressEvent) {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        setUploadProgress(percentCompleted);
    }

    function handleUpload(e) {
        if (e.target.files[0]) {
            mutate(e.target.files[0]);
        }
    }

    return {
        form: {
            handleUpload,
            isLoading,
            uploadProgress,
            fileInputRef,
            errorMessage
        }
    }
}
