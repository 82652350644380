export function useLineChartHook({ height, categories, series, maxValue, minValue, type }) {

    const options = {
        chart: {
            type: type ? type : 'line',
            height: height,
            zoom: {
                enabled: false,
            },
            events: {},
            toolbar: {
                show: false,
            },
            padding: 8
        },
        tooltip: {
            enabled: false
        },
        stroke: {
            curve: 'straight',
            lineCap: 'round',
            width: series?.map(s=>s.dashed ? 2 : 1),
            dashArray: series?.map(s=>s.dashed ? 5 : 0),
        },
        fill: {
            type: 'gradient',
            gradient: {
              shadeIntensity: 1,
              opacityFrom: 0.7,
              opacityTo: 0.9,
              stops: [0, 100]
            }
          },
        legend: {
            horizontalAlign: 'center', 
            markers: {
                size: 5,
                shape: 'square',
                strokeWidth: 4,
            },
        },
        xaxis: {
            categories: categories,
            offsetY: -1,
            axisBorder: {
                show: false,
            },
            tooltip: {
                enabled: false,
            },
            labels: {
                style: {
                    fontSize: '14px',
                },
            },
        },
        yaxis: {
            show: false,
            max: maxValue * 1.1,
            min: minValue === 0 ? maxValue * -0.1 : minValue,
            labels: {
                show: false,
            }
        },
        grid: {
            show: false
        },
        markers: {
            size: series?.map(s=>s.dashed ? 0 : 3),
            colors: series?.map(s=>s.color),
        },
        dataLabels: {
            enabled: true,
            enabledOnSeries: series?.map((s, index) => (!s.dashed ? index : null)).filter(index => index !== null),
            textAnchor: ['middle'],
            style: {
                colors: series?.map(s=>s.color),
                fontSize: '10px',
                fontWeight: 'bold',
            },
            background: {
                enabled: true,
                foreColor: '#ffffff',
                borderRadius: 4,
                padding: 8,
                borderWidth: 0,
                dropShadow: {
                    enabled: false,
                }
            },
            offsetY: -7,
            
        },
    };

    return {
        config: {
            options,
            series
        }
    };
}
