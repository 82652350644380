import { FontGreyDarkFullRegular, FontGreyDarkMediumTitle, FontGreyDarkBold, FontRedBold } from "../../../utils/typografyApp";
import { TopContainer, AnomalieInfoWindowContainer, VerticalItem, HorizontalItem, GridContainer, ImagesContainer, ViewImageBg, AnomalieInfoWindowModalContainer } from "./styled";
import SwitchButton from "../SwitchButton";
import { formatDate } from 'date-fns';
import { baseUrl } from "../../../service/api";
import { useAnomalieInfoWindowHook } from "./hook";
import edit_icon from "../../../assets/icons/edit.svg";
import remove_icon from "../../../assets/icons/remove.svg";
import check_icon from "../../../assets/icons/check.svg";
import AnomalieModal from "../Modals/AnomalieModal";
import DeleteAnomalieModal from "../Modals/DeleteAnomalieModal";
import FinalizeAnomalieModal from "../Modals/FinalizeAnomalieModal";


const AnomalieInfoWindow = ({ anomaly, section, onClose }) => {

    const hook = useAnomalieInfoWindowHook({ anomaly });

    return (
        <>
            <AnomalieInfoWindowContainer>
                <TopContainer>
                    <FontGreyDarkMediumTitle>Registro de Anomalias</FontGreyDarkMediumTitle>
                    <div className="actions">
                        <img src={check_icon} alt="Icone de Confirmar" title="Concluir" onClick={()=>hook.setShowFinalizeAnomaly(true)}/>
                        <img src={edit_icon} alt="Icone de editar" title="Editar" onClick={() => hook.setShowEditAnomaly(true)} />
                        <img src={remove_icon} alt="Icone de remover" title="Excluir" onClick={() => hook.setShowDeleteAnomaly(true)} />
                    </div>
                </TopContainer>

                <HorizontalItem>
                    <FontGreyDarkFullRegular>Área Degradada</FontGreyDarkFullRegular>
                    <FontGreyDarkMediumTitle>{anomaly.area}m²</FontGreyDarkMediumTitle>
                </HorizontalItem>

                <GridContainer>
                    <VerticalItem>
                        <FontGreyDarkFullRegular>ID</FontGreyDarkFullRegular>
                        <FontGreyDarkBold>#{anomaly.internalId}</FontGreyDarkBold>
                    </VerticalItem>
                    <VerticalItem>
                        <FontGreyDarkFullRegular>Data</FontGreyDarkFullRegular>
                        <FontGreyDarkBold>{formatDate(anomaly.date, 'dd/MM/yyyy')}</FontGreyDarkBold>
                    </VerticalItem>
                    <VerticalItem>
                        <FontGreyDarkBold>{anomaly.defectType.name}</FontGreyDarkBold>
                    </VerticalItem>
                    <VerticalItem>
                        <FontRedBold>{anomaly.degradationDegree.name}</FontRedBold>
                    </VerticalItem>
                </GridContainer>

                <HorizontalItem>
                    <FontGreyDarkFullRegular>Falha Prematura</FontGreyDarkFullRegular>
                    <SwitchButton active={anomaly.prematureFailure} />
                </HorizontalItem>

                <ImagesContainer>
                    <FontGreyDarkBold>{anomaly.medias?.length} Imagens</FontGreyDarkBold>
                    <div className="images">

                        <button onClick={hook.handlePrev} disabled={hook.currentIndex === 0}>&#9664;</button>
                        {anomaly.medias?.length > 0 && (
                            <div>
                                <img
                                    src={`${baseUrl}/${anomaly.medias[hook.currentIndex].path}`}
                                    alt="Imagem de uma anomalia cadastrada"
                                    onClick={() => { hook.setSelectedImage(anomaly.medias[hook.currentIndex].path) }}
                                />
                            </div>
                        )}
                        <button onClick={hook.handleNext} disabled={hook.currentIndex === anomaly.medias.length - 1}>&#9654;</button>
                    </div>
                </ImagesContainer>

                {
                    hook.selectedImage &&
                    <ViewImageBg onClick={() => hook.setSelectedImage(null)}>
                        <div className="viContent">
                            <img src={`${baseUrl}/${hook.selectedImage}`} alt="Imagem de uma anomalia cadastrada" />
                        </div>
                    </ViewImageBg>
                }


            </AnomalieInfoWindowContainer>
            {
                hook.showDeleteAnomaly &&
                <DeleteAnomalieModal
                    onClose={() => { hook.setShowDeleteAnomaly(false); onClose() }}
                    selectedAnomalie={anomaly}
                />
            }

            {
                hook.showFinalizeAnomaly &&
                <FinalizeAnomalieModal
                    onClose={() => { hook.setShowFinalizeAnomaly(false); onClose() }}
                    selectedAnomalie={anomaly}
                />
            }

            {
                hook.showEditAnomaly &&
                <AnomalieInfoWindowModalContainer>
                    <AnomalieModal
                        onClose={() => { hook.setShowEditAnomaly(false) }}
                        section={section}
                        anomaly={anomaly}
                        onCloseAnomalieWindow={onClose}

                    />
                </AnomalieInfoWindowModalContainer>
            }


        </>
    );
}

export default AnomalieInfoWindow;