import { styled } from 'styled-components';

export const PageContainer = styled.div`
    width: 100vw;
    height: 100vh;
    background: var(--grey-light-color) !important;

`;

export const SVGContainer = styled.div`
    width: 74vw;
    height: 85vh;
    margin: 3%;
    position: relative;
    
    .download{
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .download-text{
            margin-top: 70px;
            font-size: 20px;
            font-weight: bold;
        }

        img{
            width: 40px;
            height: 40px;
        }
       
    }

    ._3d{
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2999;
        height: 100%;
        width: 100%;
        overflow: hidden;
    }

    .refresh-icon{
        position: absolute;
        bottom: 30px;
        left: 5px;
        z-index: 9999;
        img{
            width: 30px !important;
            cursor: pointer;
        }
    }

    .progress-bar{
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 14px;
        height: 100vh;
        justify-content: center;
        div{
            height: 80px;
        }
    }

`;

export const AssetContainer = styled.div`
    position: relative;
    height: 100vh;
    overflow: scroll;

    img{
        position: absolute;
        width: 74vw;
        margin: 3%;
    }
    
`;

export const SVGAssetArea = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: ${props=>props.position.y}%;
    left: ${props=>props.position.x}%;
    width: ${props=>props.width}%;
    height: ${props=>props.height}px;

    svg{
        width: 100%;
        height: 100%;
    }

`;

export const SVGRIArea = styled.div`
    position: absolute;
    left: ${props=>props.position.x}%;
    width: ${props=>props.width}%;
    height: 0;
    padding-bottom: ${props=>props.width}%; 
    border: 3px solid ${props=>props.color};
    border-radius: 100rem;
    
    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: ${props=>props.color};
        border-radius: 100rem;
        opacity: 0.32;
    }
    
    
`;


export const PinStatus = styled.div`
    position: absolute;
    top: ${props=>props.position.y-6}%;
    left: ${props=>props.position.x+3}%;
    cursor: pointer;
    z-index: 3000;

    .circle-status{
        width: 0.35rem;
        height: 0.35rem;
        background-color: ${props=>props.color};
        border-radius: 1rem;
        border: 0.15rem solid var(--grey-dark-color);
    }

    .border-status{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1rem;
        height: 1rem;
        background-color: var(--grey-medium-color);
        border-radius: 1rem;
        margin-top: 0.15rem;
    }
`;  