import styled from "styled-components";


export const AnomalieInfoWindowContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;

    div{
        border-radius: 8px;
        border: 1px solid var(--grey-light-2-color);
        background: var(--white-color);
    }
`;

export const HorizontalItem = styled.div`
    display: flex;
    padding: 4px 2rem;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
`;

export const VerticalItem = styled.div`
    display: flex;
    padding: 4px 2rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
    flex: 1 0 0;

    span{
        border: none;
    }
`;

export const ImagesContainer = styled.section`
   display: flex;
   flex-direction: column;
   align-items: center;
   gap: 8px;

   .images{
        display: flex;
   }

   div{
    border: none;
   }

   img {
      position: relative;
      width: 330px; /* Ajuste para tamanho maior, se preferir */
      height: 180px;
      background-color: #cecece;
      border-radius: 8px;
      border: none !important; 
      cursor: zoom-in;
      margin: 8px 0;
   }

   button {
      margin: 8px;
      padding: 8px 8px;
      background-color: #007bff;
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      
      &:disabled {
         background-color: #cecece;
         cursor: not-allowed;
      }
   }
`;


export const GridContainer = styled.section`
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
`;

export const TopContainer = styled.section`
    display: flex;
    flex-direction: row;
    align-items: start;
    gap: 0.5rem;
    align-self: stretch;
    align-items: start;
    justify-content: space-between;

    .actions{
        display: flex;
        flex-direction: row;
        gap: 8px;
        border: none;
        
        img{
            position: relative;
            width: 24px;
            height: 24px;
            cursor: pointer
        }
    }
`;

export const AnomalieInfoWindowModalContainer = styled.div`
    position: fixed;
    top : 0;
    left: 0;

    img{
        position: relative !important;
    }

`;


export const ViewImageBg = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    background-color: #00000033 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
    cursor:pointer;

    .viContent{
        width: 900px;
        height: 400px;
        padding: 20px;
        
        img {
            max-width: 900px !important;
            max-height: 400px;
            object-fit: contain;
        }
    }
`