import { z } from "zod";
import { fkSchemaOptional, generalMessageError } from "./config";
import { generateRandomId } from "../../utils/functions";

export const priorizationSchema = z.object({
    internalId: z.string().min(1, { message: generalMessageError }),
    date: z.string().min(1, { message: generalMessageError }),
    area: z.string().min(1, { message: generalMessageError }),
    type: z.string().min(1, { message: generalMessageError }),
    activityDurationDaysEffective: z.string().min(1, { message: generalMessageError }),
    totalBilling: z.string().min(1, { message: generalMessageError }),
    totalArea: z.string().min(1, { message: generalMessageError }),
    isPrioritized: z.boolean().default(true),
    fkSectionsId: fkSchemaOptional,
});