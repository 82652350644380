import JSZip from "jszip";
import { baseUrl } from "./api";

const dbName = 'tauLocalData';
const storeName = 'stlFiles';

// Função para abrir/criar o banco de dados e o objeto de armazenamento, se necessário
const openDatabase = (prefix) => {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open(`${prefix}-${dbName}`, 1);

        request.onupgradeneeded = (event) => {
            const db = event.target.result;
            if (!db.objectStoreNames.contains(storeName)) {
                db.createObjectStore(storeName);
            }
        };

        request.onsuccess = (event) => {
            resolve(event.target.result);
        };

        request.onerror = (event) => {
            reject(event);
        };
    });
};

// Função para salvar dados em IndexedDB
export const saveToIndexedDB = (key, data, prefix) => {
    return new Promise((resolve, reject) => {
        openDatabase(prefix)
            .then((db) => {
                const transaction = db.transaction(storeName, "readwrite");
                const store = transaction.objectStore(storeName);
                const putRequest = store.put(data, `${prefix}-${key}`);
                
                putRequest.onsuccess = () => resolve();
                putRequest.onerror = (e) => reject(e);
            })
            .catch((e) => reject(e));
    });
}

// Função para ler dados em IndexedDB
export const getFromIndexedDB = (key, prefix) => {
    return new Promise((resolve, reject) => {
        openDatabase(prefix)
            .then((db) => {
                const transaction = db.transaction(storeName, "readonly");
                const store = transaction.objectStore(storeName);
                const getRequest = store.get(`${prefix}-${key}`);

                getRequest.onsuccess = () => {
                    resolve(getRequest.result);
                };
                getRequest.onerror = (e) => {};
            })
            .catch((e) => {});
    });
}

// Função para excluir o banco de dados IndexedDB
export const deleteDatabase = async (prefix) => {
    return new Promise((resolve, reject) => {
        const request = indexedDB.deleteDatabase(`${prefix}-${dbName}`);
        request.onsuccess = () => resolve();
        request.onerror = (event) => reject(event.target.error);
        request.onblocked = () => {
            console.warn("A exclusão do banco de dados foi bloqueada");
        };
    });
};

// Função para fazer o download e extrair o ZIP
export const downloadAndExtractZip = async (prefix, e, asset3DItem, setDownloadProgress, setIsDownloading, refetch, setExtract) => {
    
    if (!asset3DItem) {
        alert('Esse ativo não está conectado com nenhum modelo 3D');
    }else{
        if(e)
            e.stopPropagation();
        setIsDownloading(asset3DItem.id);
        try {
            // Exclui o banco de dados antes de iniciar o download
            await deleteDatabase(prefix);

            const url = `${baseUrl}/${asset3DItem.location}`;

            const xhr = new XMLHttpRequest();
            xhr.open('GET', url, true);
            xhr.responseType = 'blob';

            xhr.onprogress = (event) => {
                if (event.lengthComputable) {
                    const progress = Math.round((event.loaded / event.total) * 100);
                    setDownloadProgress(progress);
                }
            };

            xhr.onload = async () => {
                if (xhr.status === 200) {
                    if(setExtract)
                        setExtract(true);
                    const blob = xhr.response;
                    const zip = await JSZip.loadAsync(blob);
            
                    const filePromises = [];
            
                    // Usar for...of para garantir execução síncrona
                    for (const relativePath of Object.keys(zip.files)) {
                        const file = zip.files[relativePath];
                        const fileData = await file.async("blob");
            
                        // Adiciona a promessa para salvar no IndexedDB
                        filePromises.push(saveToIndexedDB(`${relativePath}`, fileData, prefix));
                    }
            
                    // Aguarde que todas as promessas sejam concluídas
                    await Promise.all(filePromises);
            
                    localStorage.setItem(`assets-${prefix}`, asset3DItem.token);
                    setTimeout(() => {
                        if (prefix.includes('home')) {
                            window.location.reload();
                        } else {
                            refetch();
                            setDownloadProgress(0);
                            setIsDownloading(false);
                            if(setExtract)
                                setExtract(false);
                        }
                    }, 3000);
            
                } else {
                    throw new Error("Erro ao fazer o download do arquivo");
                   
                }
            };

            xhr.onerror = () => {
                setIsDownloading(false);
                if(setExtract)
                    setExtract(false);
                console.error("Erro ao fazer o download do arquivo");
            };

            xhr.send();
        } catch (error) {
            console.error("Erro ao fazer o download e extrair o ZIP:", error);
            setIsDownloading(false);
        }
    }
};
