import AdmButton from "../../../../components/AdmButton"
import { TabAssetContainer, TabAssetSectionItem, TabAssetSections, TabAssetFilters } from "./styled"
import arrow_down_icon from '../../../../../assets/icons/arrow_down.svg';
import upload_grey_icon from '../../../../../assets/icons/upload_grey.svg';
import search_icon from '../../../../../assets/icons/search.svg';
import tag_grey_icon from '../../../../../assets/icons/tag_grey.svg';
import edit_icon from '../../../../../assets/icons/edit.svg';
import remove_icon from '../../../../../assets/icons/remove.svg';
import { FontGreyDarkBold, FontGreyDarkMedium } from "../../../../../utils/typografyApp";
import Input from "../../../../components/Input";
import { useState } from "react";
import NewAssetModal from "../../../../components/Modals/NewAssetModal";
import NewSectionModal from "../../../../components/Modals/NewSectionModal";
import { useTabAssetsHook } from "./hook";
import { Loading } from "../../../../components/Loading";
import BulkAssetModal from "../../../../components/Modals/BulkAssetsModal";
import DeleteAssetModal from "../../../../components/Modals/DeleteAssetModal";
import DeleteSectionModal from "../../../../components/Modals/DeleteSectionModal";

export const TabAssets = () => {
    const [showNewSection, setShowNewSection] = useState(false);

    const hook = useTabAssetsHook();

    return (
        <TabAssetContainer>
            <FontGreyDarkBold>
                <table>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Nome do ativo</th>
                            <th>Tag</th>
                            <th></th>
                        </tr>

                    </thead>

                    <tbody>
                        <tr>
                            <td colSpan={hook.general.colSpan}>
                                <TabAssetFilters>
                                    <Input className={'search'} icon={search_icon} placeholder={'Buscar ativos'} setSearch={hook.general.setSearch} />

                                    {/* <AdmButton onClick={() => { hook.general.setShowNewAssetModal(true) }}>
                                        <img src={add_icon} alt="Iocne de adicionar" />
                                        Novo Ativo
                                    </AdmButton> */}

                                    <AdmButton onClick={() => { hook.general.setShowBulkAssetModal(true) }}>
                                        <img src={upload_grey_icon} alt="Icone de upload" />
                                        Ativo em Massa
                                    </AdmButton>
                                </TabAssetFilters>
                            </td>
                        </tr>


                        {hook.assets.assetsData?.count === 0 || hook.assets.isLoading ?
                            <tr>
                                <td colSpan={hook.general.colSpan} className="empty-table">
                                    {hook.assets.isLoading ?
                                        <Loading /> :
                                        <div>Nenhum ativo cadastrado</div>
                                    }
                                </td>
                            </tr> :
                            <>
                                {hook.assets.assetsData?.rows?.map(asset => (
                                    <>
                                        <tr onClick={() => hook.general.handleViewAsset(asset)} className="click">
                                            <td><img className={`arrow ${hook.general.viewAsset === asset && 'rotate'}`} src={arrow_down_icon} alt="Icone de seta" /></td>
                                            <td>{asset.name}</td>
                                            <td>
                                                <div className="tag">
                                                    <img src={tag_grey_icon} alt="Icone de seta" />
                                                    {asset.code}
                                                </div>
                                            </td>
                                            <td>
                                                <div className="actions">
                                                    <img src={edit_icon} alt="Icone de editar" onClick={(e) => { hook.general.setShowNewAssetModal(asset); e.stopPropagation() }} />
                                                    <img src={remove_icon} alt="Icone de Remover" onClick={(e) => { hook.general.setShowDeleteAssetModal(asset); e.stopPropagation()}} />
                                                </div>
                                            </td>
                                        </tr>

                                        {hook.general.viewAsset === asset &&
                                            <tr>
                                                <td colSpan={4}>
                                                    <TabAssetSections>
                                                        <FontGreyDarkMedium>Seções</FontGreyDarkMedium>
                                                        <div className="sections">
                                                            {asset.sections.map(section => (
                                                                <TabAssetSectionItem>
                                                                    <span className="name"> {section.name} </span>
                                                                    <img src={edit_icon} alt="Icone de editar" onClick={(e)=>{hook.general.setShowNewSectionModal(section); e.stopPropagation()}}/>
                                                                    <img src={remove_icon} alt="Icone de Remover" onClick={(e)=>{hook.general.setShowDeleteSectionModal(section); e.stopPropagation()}}/>
                                                                </TabAssetSectionItem>
                                                            ))}
                                                        </div>
                                                        {/* <AdmButton onClick={() => { setShowNewSection(true) }}>
                                                            <img src={add_icon} alt="Icone de adicionar" />
                                                            Seção
                                                        </AdmButton> */}
                                                    </TabAssetSections>
                                                </td>
                                            </tr>
                                        }
                                    </>
                                ))
                                }
                            </>
                        }
                    </tbody>
                </table>
            </FontGreyDarkBold>

            {hook.general.showNewAssetModal && <NewAssetModal selectedAsset3DItem={hook.general.showNewAssetModal} onClose={() => hook.general.setShowNewAssetModal(null)} />}
            {hook.general.showDeleteAssetModal && <DeleteAssetModal onClose={()=>hook.general.setShowDeleteAssetModal(null)} selectedAsset={hook.general.showDeleteAssetModal}/>}
            {hook.general.showDeleteSectionModal && <DeleteSectionModal onClose={()=>hook.general.setShowDeleteSectionModal(null)} selectedSection={hook.general.showDeleteSectionModal}/>}
            {hook.general.showNewSectionModal && <NewSectionModal onClose={()=>hook.general.setShowNewSectionModal(null)} selectedSection={hook.general.showNewSectionModal}/>}
            {hook.general.showBulkAssetModal && <BulkAssetModal onClose={() => hook.general.setShowBulkAssetModal(false)} />}
            
        </TabAssetContainer>
    )
}