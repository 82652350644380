import { useMutation } from "react-query";
import { queryClient } from "../../../../utils/query-client";
import { sectionsQueries, useSectionsService } from "../../../../services/sectionService";
import { assetsQueries } from "../../../../services/assetService";

export function useDeleteSectionModalHook({onClose, selectedSection}){
    const sectionService = useSectionsService();

    const { mutate, isLoading } = useMutation((payload) => sectionService.deleteSections({id : selectedSection.id, onSuccess}));

    function onSuccess() {
        queryClient.refetchQueries(assetsQueries.GET_ALL);
        onClose();
    }

    function onSubmit() {
        mutate();
    }

    return{
        form: {
            onSubmit,
            isLoading
        }
    }
}