import ReactApexChart from "react-apexcharts";
import { LineChartContainer, LineChartSacaleContainer, GraphicFooter, FooterLegendContainer } from "./styled";
import { FontGreyMediumRegular, FontGreyDarkRegular} from "../../../utils/typografyApp";
import { useLineChartHook } from "./hook";
import { Loading } from "../Loading";

export const LineChartComponent = ({ height, width, type, categories, series, maxValue, minValue, color, isLoading }) => {


    const hook = useLineChartHook({ height, width, type, categories, series, maxValue, minValue, color });

    return (
        <>
            {isLoading || !hook.config.series ?
                <Loading size={24} /> :

                <LineChartContainer height={height}>
                    <LineChartSacaleContainer>
                        <FontGreyMediumRegular>x 100</FontGreyMediumRegular>
                    </LineChartSacaleContainer>
                    <ReactApexChart
                        series={hook.config.series}
                        options={hook.config.options}
                        type={type ?? 'line'}
                        background={8}
                        height={height}
                        width={width}
                    />

                </LineChartContainer>
            }
        </>

    )
}