import { useMutation } from "react-query";
import { useForm } from "react-hook-form";
import { queryClient } from "../../../../utils/query-client";
import { zodResolver } from '@hookform/resolvers/zod';
import { assets3dItemQueries, useAssets3DItemService } from "../../../../services/asset3DItemService";
import { asset3DItemSchema } from "../../../../models/schemas/asset3DItemSchema";

export function useNewAsset3DItemModalHook({onClose, selectedAsset3DItem}){
    const asset3DItemService = useAssets3DItemService();

    const { mutate, isLoading } = useMutation((payload) => 
        selectedAsset3DItem ?
        asset3DItemService.editAssets3DItem({payload, onSuccess}) :
        asset3DItemService.createAssets3DItem({ payload, onSuccess })
    );

    const asset3DItemForm = useForm({ 
        resolver: zodResolver(asset3DItemSchema), 
        defaultValues: selectedAsset3DItem
    });

    function onSuccess() {
        queryClient.refetchQueries(assets3dItemQueries.GET_ALL);
        onClose();
    }


    function onSubmit(payload) {
        //TODO: lembrar de trocar o id do client
        mutate({...payload, client: {id : 1}, location: ''});
    }

    return{
        form: {
            onSubmit,
            asset3DItemForm,
            isLoading
        }
    }
}