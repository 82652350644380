import { ModalMain } from "../styled";
import { FontGreyDarkFullRegular, FontGreyDarkMediumTitle } from "../../../../utils/typografyApp";
import SecondaryButton from "../../SecondaryButton";
import PrimaryButton from "../../PrimaryButton";
import { FinalizeAnomalieForm } from "./styled";
import { useFinalizeAnomalieModalHook } from "./hook";
import { FormProvider } from "react-hook-form";

const FinalizeAnomalieModal = ({ onClose, selectedAnomalie }) => {

    const hook = useFinalizeAnomalieModalHook({ onClose, selectedAnomalie });

    return (
        <ModalMain>
            <FormProvider {...hook.form.asset3DItemForm}>
                <FinalizeAnomalieForm>
                    
                        <div className={"menu-content"}>
                            <div className="content">
                                <div className="title">
                                    <FontGreyDarkMediumTitle>Concluir Anomalia</FontGreyDarkMediumTitle>
                                </div>
                                <FontGreyDarkFullRegular>Ao concluir a anomalia ela não aparecerá mais no modelo. Continue somente se a anomalia estiver sido resolvida. Continuar?</FontGreyDarkFullRegular>
                            </div>

                            <div className="actions-container">
                                <SecondaryButton disabled={hook.form.isLoading} onClick={onClose}>Voltar</SecondaryButton>
                                <PrimaryButton disabled={hook.form.isLoading} onClick={hook.form.onSubmit}> {hook.form.isLoading ? 'Concluindo...' : 'Continuar'} </PrimaryButton>
                            </div>
                        </div>
            
                </FinalizeAnomalieForm>
            </FormProvider>
        </ModalMain>
    );
}

export default FinalizeAnomalieModal;