import { useMutation } from "react-query";
import { queryClient } from "../../../../utils/query-client";
import { assetsQueries, useAssetsService } from "../../../../services/assetService";

export function useDeleteAssetModalHook({onClose, selectedAsset}){
    const assetService = useAssetsService();

    const { mutate, isLoading } = useMutation((payload) => assetService.deleteAssets({id : selectedAsset.id, onSuccess}));

    function onSuccess() {
        queryClient.refetchQueries(assetsQueries.GET_ALL);
        onClose();
    }

    function onSubmit() {
        mutate();
    }

    return{
        form: {
            onSubmit,
            isLoading
        }
    }
}