import ReactApexChart from "react-apexcharts";
import { BarChartContainer, BarChartSacaleContainer } from "./styled";
import { FontGreyMediumRegular } from "../../../utils/typografyApp";
import { useBarChartHook } from "./hook";
import { Loading } from "../Loading";

export const BarChartComponent = ({ height, width, categories, hiddenLegend, series, maxValue, minValue, color, isLoading }) => {


    const hook = useBarChartHook({ height,width, categories, series, maxValue, minValue, color });
   
    return (
        <>
            {isLoading  || !hook.config.series ?
                <Loading size={24} /> :

                <BarChartContainer height={height} hiddenLegend={hiddenLegend}>
                    <BarChartSacaleContainer>
                        <FontGreyMediumRegular>x 100</FontGreyMediumRegular>
                    </BarChartSacaleContainer>
                    <ReactApexChart
                        series={hook.config.series}
                        options={hook.config.options}
                        type='bar'
                        height={height}
                        width={width}

                    />

                </BarChartContainer>
            }
        </>

    )
}