import { useEffect, useState } from "react";
import { metricsQueries, useMetricsService } from "../../../services/metricsService";
import { useQuery } from "react-query";

export function useMenuHook({asset, section}){
    const [showDashboardCard, setShowDashboardCard] = useState(true);
    const metricsService = useMetricsService();
    const [data, setData] = useState();

    const {
        data : metricsData,
        isLoading : isLoadingMetrics,
        isRefetching : isRefetchingMetrics,
        refetch : refetchMetrics
    } = useQuery(metricsQueries.GET_BY_CLIENT, ()=>metricsService.getAllByClient({clientId: !asset && !section ? 1 : undefined}));

    const {
        data : metricsAssetData,
        isLoading : isLoadingMetricsAsset,
        isRefetching : isRefetchingMetricsAsset,
        refetch : refetchMetricsAsset
    } = useQuery(section ? metricsQueries.GET_BY_SECTION_ID : metricsQueries.GET_BY_ID, ()=>metricsService.getMetricsById({ id: section?.fkAssetId || asset?.id, fkSectionId: section?.id}));

    const dashboardData = !asset && !section ? metricsData : metricsAssetData;

    useEffect(()=>{
        refetchMetricsAsset();
    }, [asset, section]);

    useEffect(()=>{
        const categories = dashboardData?.dueDates?.slice(0, 4)?.map(item=>item.labelYear) || [];
        const dataValues = dashboardData?.dueDates?.slice(0, 4)?.map(item => parseFloat(item.totalArea[0] / 100).toFixed(2)) || [];
        const maxValue = Math.max(...dataValues);
        const minValue = Math.min(...dataValues);

        setData({
            categories, 
            series: [{
                color: "#ff0000",
                data: dataValues
            }], 
            maxValue, 
            minValue
        });
    }, dashboardData);

    return{
        chats: {
            data,
            dashboardData,
            isLoading: 
                ((!asset && !section) && (isLoadingMetrics || isRefetchingMetrics)) || 
                ((asset || section) && (isLoadingMetricsAsset || isRefetchingMetricsAsset)),
            refetchMetrics,
            refetchMetricsAsset
        },
        general: {
            showDashboardCard,
            setShowDashboardCard
        }
    }
}