import { useMutation, useQuery } from "react-query";
import { useForm } from "react-hook-form";
import { queryClient } from "../../../../utils/query-client";
import { zodResolver } from '@hookform/resolvers/zod';
import { assetSchema } from "../../../../models/schemas/assetSchema";
import { assetsQueries, useAssetsService } from "../../../../services/assetService";
import { unitsQueries, useUnitsService } from "../../../../services/unitService";
import { useEffect, useState } from "react";
import { useSystemTypesService } from "../../../../services/systemTypesService";

export function useNewAssetModalHook({onClose, selectedAsset}){

    const assetService = useAssetsService();
    const unitService = useUnitsService();
    const systemTypesService = useSystemTypesService();

    const {
        data: unitsData,
        isLoading: isLoadingUnits,
        isRefetching: isRefetchingUnits,
        refetch: refetchUnits
        //TODO: trocar a paginacao
    } = useQuery(unitsQueries.GET_ALL, () => unitService.getAllUnitsOptions({search: '', page: 1}));


    const { mutate, isLoading } = useMutation((payload) => 
        selectedAsset ?
        assetService.editAssets({payload, onSuccess}) :
        assetService.createAssets({ payload, onSuccess })
    );

    const assetForm = useForm({ 
        resolver: zodResolver(assetSchema)
    });

    function onSuccess() {
        queryClient.refetchQueries(assetsQueries.GET_ALL);
        onClose();
    }


    function onSubmit(payload) {
        //TODO: lembrar de trocar o id do client
        mutate({...payload, client: {id : 1}});
    }

    useEffect(()=>{
        if(selectedAsset){
            assetForm.reset(selectedAsset);
        }
    }, [selectedAsset]);
    return{
        form: {
            onSubmit,
            assetForm,
            isLoading
        },
        units: {
            unitsData,
            isLoading: isLoadingUnits || isRefetchingUnits,
            refetchUnits
        }
    }
}