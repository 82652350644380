import styled from "styled-components";

export const LineChartContainer = styled.div`
    position: relative;
    border: 1px solid var(--grey-light-2-color);
    height: ${props=>props.height ?? 140}px;
    margin-bottom: 45px;
    border-radius: 8px;
    padding-top: 12px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .hidden-legend{
        .apexcharts-legend {
            display: none !important;
        }
    }
`;

export const LineChartSacaleContainer = styled.div`
    position: absolute;
    top: 4px;
    left: 8px;
`;