import { ModalMain } from "../styled";
import arrow_bottom_icon from "../../../../assets/icons/arrow_bottom.svg"
import { FontGreyDarkMediumTitle, FontGreyDarkRegular, FontInputGreyDarkRegular, FontPrimaryMediumTitle } from "../../../../utils/typografyApp";
import SecondaryButton from "../../SecondaryButton";
import { FormContainer, RowContainer, SwitchContainer } from "./styled";
import Input from "../../Input";
import Select from "../../Select";
import SwitchButton from "../../SwitchButton";
import PrimaryButton from "../../PrimaryButton";
import { useEffect, useState } from "react";
import PinAnomalies from "../../PinAnomalies";
import success_icon from "../../../../assets/icons/success.svg";
import api from "../../../../service/api";
import { useExecutionHook } from "./hook";
import { FormProvider } from "react-hook-form";
import { Canvas } from "react-three-fiber";
import { OrbitControls, PerspectiveCamera } from "@react-three/drei";
import { Loading } from "../../Loading";

const key1 = Math.random();
const key2 = Math.random();

const ExecutionModal = ({ onClose, setOpacity, section }) => {
    const hook = useExecutionHook({ section, setOpacity, onClose });
    console.log(hook.form.executionForm.formState.errors)
    return (
        <ModalMain>
            <FormProvider {...hook.form.executionForm}>
                <form onSubmit={hook.form.executionForm.handleSubmit(hook.form.onSubmit)}>
                    <div className={hook.general.tab !== hook.general.tabs[0] && "hidden-content"}>
                        <div className="content">
                            <div className="title">
                                <FontGreyDarkMediumTitle>Registro de Execução</FontGreyDarkMediumTitle>
                                <img src={arrow_bottom_icon} alt="Icone de adicionar" />
                            </div>
                            <FormContainer>
                                {!section &&
                                    <RowContainer>
                                        <Select
                                            type="text"
                                            placeholder="Selecione a Área"
                                            name="fkSectionsId"
                                            options={hook.sections.sectionOptions}
                                            defaultValue={section.name}
                                        />
                                    </RowContainer>
                                }
                                <RowContainer>
                                    <Input type="number" placeholder="Área Total Tratada" name="area" />
                                </RowContainer>
                                <RowContainer>
                                    <Input type="text" placeholder="ID" name="internalId" />
                                    <Input type="date" placeholder="Data" name="date" />
                                </RowContainer>

                                <RowContainer>
                                    <Input type="number" placeholder="Duração da Atividade" name="activityDurationDaysEffective" />
                                    <Input type="number" placeholder="Custo Total" name="totalBilling" />
                                </RowContainer>

                                <RowContainer>
                                    <Select
                                        className={'schema'}
                                        type="text"
                                        placeholder="Esquema Aplicado"
                                        name="fkPaintingSchemaId"
                                        options={hook.schemas.schemasData}
                                    />
                                    <Input type="date" placeholder="Vida Útil" name="lifespan" />
                                </RowContainer>

                            </FormContainer>
                        </div>
                        <div className="actions-container">
                            <SecondaryButton onClick={onClose}>Voltar</SecondaryButton>
                            <PrimaryButton type="button" onClick={hook.form.validateForm}>Avançar</PrimaryButton>
                        </div>
                    </div>
                    <div className={`${hook.general.tab !== hook.general.tabs[1] && "hidden-content"}`} >
                        <div className="_3d">
                            {hook.config3d.isLoading && (
                                <div className="progress-bar">
                                    <Loading />
                                    <FontGreyDarkRegular><div className="progress-text"> Carregando: {Math.round(hook.config3d.progress)}%</div></FontGreyDarkRegular>
                                </div>
                            )}
                            <Canvas key={key1}>
                                <ambientLight intensity={0.5} />
                                <directionalLight
                                    position={[20, 10, 10]}
                                    castShadow
                                    shadow-mapSize-width={1024}
                                    shadow-mapSize-height={1024}
                                    shadow-camera-far={50}
                                    shadow-camera-left={-10}
                                    shadow-camera-right={10}
                                    shadow-camera-top={10}
                                    shadow-camera-bottom={-10}
                                />
                                <PerspectiveCamera ref={hook.config3d.camera} makeDefault position={[-2, 1, 1]} />
                                <OrbitControls ref={hook.config3d.orbitControls} />
                                {hook.config3d.objects?.map((object, index) => (
                                    object.object ?
                                        <primitive
                                            key={index}
                                            object={object.object}
                                            onDoubleClick={hook.config3d.handleObjectClick}

                                        /> :
                                        <></>
                                ))}
                            </Canvas>
                        </div>
                        <div className="float-top">
                            <div className="content">
                                <div className="title underline">
                                    <FontGreyDarkMediumTitle>
                                        <FontPrimaryMediumTitle>Clique duas vezes </FontPrimaryMediumTitle>
                                        na {hook.selectedSection.selectedSection?.name} para registrar uma execução
                                    </FontGreyDarkMediumTitle>
                                </div>
                            </div>
                            <div className="actions-container">
                                <SecondaryButton onClick={() => hook.general.setTab(hook.general.tabs[0])} >Voltar</SecondaryButton>
                                <PrimaryButton
                                    type="button"
                                    onClick={() => hook.general.setTab(hook.general.tabs[2])}
                                    disabled={!hook.config3d.sphere}
                                >
                                    Avançar
                                </PrimaryButton>
                            </div>
                        </div>
                    </div>

                    <div className={`${hook.general.tab !== hook.general.tabs[2] && "hidden-content"}`} >
                        <div className="_3d">
                            {hook.config3dAnomaly.isLoadingAnomaly && (
                                <div className="progress-bar">
                                    <Loading />
                                    <FontGreyDarkRegular><div className="progress-text"> Carregando: {Math.round(hook.config3dAnomaly.progressAnomaly)}%</div></FontGreyDarkRegular>
                                </div>
                            )}
                            <Canvas key={key2}>
                                <ambientLight intensity={0.5} />
                                <directionalLight
                                    position={[20, 10, 10]}
                                    castShadow
                                    shadow-mapSize-width={1024}
                                    shadow-mapSize-height={1024}
                                    shadow-camera-far={50}
                                    shadow-camera-left={-10}
                                    shadow-camera-right={10}
                                    shadow-camera-top={10}
                                    shadow-camera-bottom={-10}
                                />
                                <PerspectiveCamera ref={hook.config3dAnomaly.cameraAnomaly} makeDefault position={[-2, 1, 1]} />
                                <OrbitControls ref={hook.config3dAnomaly.orbitControlsAnomaly} />
                                {hook.config3dAnomaly.objectsAnomaly?.map((object, index) => (
                                    object.object ?
                                        <primitive
                                            key={index}
                                            object={object.object}
                                            onClick={object.type ==='anomaly' ? (event)=>hook.config3dAnomaly.handleObjectClickAnomaly(event, object.anomaly) : ()=>{}}

                                        /> :
                                        <></>
                                ))}
                            </Canvas>
                        </div>
                        <div className="float-top">
                            <div className="content">
                                <div className="title underline">
                                    <FontGreyDarkMediumTitle>
                                        <FontPrimaryMediumTitle>Clique {' '}</FontPrimaryMediumTitle>
                                        em uma anomalia para marcar como resolvida
                                    </FontGreyDarkMediumTitle>
                                </div>
                            </div>
                            <div className="actions-container">
                                <SecondaryButton onClick={() => hook.general.setTab(hook.general.tabs[1])} >Voltar</SecondaryButton>
                                <PrimaryButton
                                    type="button"
                                    onClick={() => hook.general.setTab(hook.general.tabs[3])}
                                    disabled={!hook.config3d.sphere}
                                >
                                    Avançar
                                </PrimaryButton>
                            </div>
                        </div>
                    </div>

                    <div className={`add_image_container ${hook.general.tab !== hook.general.tabs[3] && "hidden-content"}`} >
                        <label to={"addImage"}>
                            <input id="addImage" type="file" accept="image/*" multiple name="medias" {...hook.form.executionForm.register('medias')} />
                            <div className="content">
                                <div className="title underline">
                                    <FontGreyDarkMediumTitle>
                                        <FontPrimaryMediumTitle>Adicine </FontPrimaryMediumTitle>
                                        fotos do local
                                    </FontGreyDarkMediumTitle>

                                </div>
                                <div className="images">
                                    {
                                        Array.from(hook.form.watchMedias || [])?.map(media => (
                                            <img src={URL.createObjectURL(media)} />
                                        ))
                                    }
                                </div>
                            </div>
                        </label>
                        <div className="actions-container">
                            <SecondaryButton onClick={() => hook.general.setTab(hook.general.tabs[2])}>Voltar</SecondaryButton>
                            <PrimaryButton type="submit" disabled={hook.form.isLoading || (hook.form.watchMedias && hook.form.watchMedias.length === 0)}>
                                {hook.form.isLoading ? 'Salvando...' : 'Concluir'}
                            </PrimaryButton>
                        </div>
                    </div>

                    <div className={`${hook.general.tab !== hook.general.tabs[4] && "hidden-content"}`} >
                        <div className="content">
                            <div className="title">
                                <FontGreyDarkMediumTitle>Novo registro de execução feito!</FontGreyDarkMediumTitle>
                                <img src={success_icon} alt="Icone de Sucesso" />
                            </div>
                        </div>
                    </div>
                </form>
            </FormProvider>
        </ModalMain >
    );
}

export default ExecutionModal;