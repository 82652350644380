import { useQuery } from "react-query";

import { useEffect, useState } from "react";
import { assetsQueries, useAssetsService } from "../../../../../services/assetService";

export function useTabAssetsHook(){
    const assetsService = useAssetsService();
    const [showNewAssetModal, setShowNewAssetModal] = useState(null);
    const [showDeleteAssetModal, setShowDeleteAssetModal] = useState(null);
    const [showNewSectionModal, setShowNewSectionModal] = useState(null);
    const [showDeleteSectionModal, setShowDeleteSectionModal] = useState(null);
    const [showBulkAssetModal, setShowBulkAssetModal] = useState(false);
    const [selectedAsset, setSelectedAsset] = useState();
    const [search, setSearch] = useState('');
    const [viewAsset, setViewAsset] = useState();

    const {
        data: assetsData,
        isLoading: isLoadingAsset,
        isRefetching: isRefetchingAsset,
        refetch: refetchAsset
        //TODO: trocar a paginacao
    } = useQuery(assetsQueries.GET_ALL, () => assetsService.getAllAssets({page: 1, name: search}));

    function handleSelectAsset(e, assets){
        e.stopPropagation();
        setSelectedAsset(assets);
        setShowNewAssetModal(true);
    }

    function handleCloseModal(){
        setSelectedAsset(null);
        setShowNewAssetModal(false);
    }

    function handleViewAsset(asset) {
        if (viewAsset === asset) {
            setViewAsset(null);
        } else {
            setViewAsset(asset);
        }
    }

    useEffect(()=>{
        refetchAsset();
    }, [search]);

    return{
        assets: {
            assetsData,
            isLoading: isLoadingAsset || isRefetchingAsset,
            refetchAsset,
            selectedAsset,
            handleSelectAsset,
            handleViewAsset,
            
        },
        general: {
            showDeleteAssetModal,
            setShowDeleteAssetModal,
            showNewAssetModal, 
            setShowNewAssetModal,
            showDeleteSectionModal,
            setShowDeleteSectionModal,
            showNewSectionModal, 
            setShowNewSectionModal,
            showBulkAssetModal,
            setShowBulkAssetModal,
            colSpan: 5,
            search,
            setSearch,
            handleCloseModal,
            handleViewAsset,
            viewAsset
        }
    }
}