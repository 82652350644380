import { useMutation, useQuery } from "react-query";
import { useForm } from "react-hook-form";
import { queryClient } from "../../../../utils/query-client";
import { zodResolver } from '@hookform/resolvers/zod';
import { sectionSchema } from "../../../../models/schemas/sectionSchema";
import { sectionsQueries, useSectionsService } from "../../../../services/sectionService";
import { unitsQueries, useUnitsService } from "../../../../services/unitService";
import { useEffect, useState } from "react";
import { useSystemTypesService } from "../../../../services/systemTypesService";
import { assetsQueries } from "../../../../services/assetService";

export function useNewSectionModalHook({onClose, selectedSection}){

    const sectionService = useSectionsService();
    const unitService = useUnitsService();
    const systemTypesService = useSystemTypesService();

    const {
        data: unitsData,
        isLoading: isLoadingUnits,
        isRefetching: isRefetchingUnits,
        refetch: refetchUnits
        //TODO: trocar a paginacao
    } = useQuery(unitsQueries.GET_ALL, () => unitService.getAllUnitsOptions({search: '', page: 1}));


    const { mutate, isLoading } = useMutation((payload) => 
        selectedSection ?
        sectionService.editSections({payload, onSuccess}) :
        sectionService.createSections({ payload, onSuccess })
    );

    const sectionForm = useForm({ 
        resolver: zodResolver(sectionSchema)
    });

    function onSuccess() {
        queryClient.refetchQueries(assetsQueries.GET_ALL);
        onClose();
    }


    function onSubmit(payload) {
        //TODO: lembrar de trocar o id do client
        mutate({...payload, client: {id : 1}});
    }

    useEffect(()=>{
        if(selectedSection){
            sectionForm.reset(selectedSection);
        }
    }, [selectedSection]);
    return{
        form: {
            onSubmit,
            sectionForm,
            isLoading
        },
        units: {
            unitsData,
            isLoading: isLoadingUnits || isRefetchingUnits,
            refetchUnits
        }
    }
}