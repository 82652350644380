import { ModalMain } from "../styled";
import { FontGreyDarkFullBold, FontGreyDarkFullRegular, FontGreyDarkMediumTitle } from "../../../../utils/typografyApp";
import SecondaryButton from "../../SecondaryButton";
import PrimaryButton from "../../PrimaryButton";
import { DeleteAssetForm } from "./styled";
import { useDeleteAssetModalHook } from "./hook";
import { FormProvider } from "react-hook-form";

const DeleteAssetModal = ({ onClose, selectedAsset }) => {

    const hook = useDeleteAssetModalHook({ onClose, selectedAsset });
    console.log(selectedAsset)
    return (
        <ModalMain>
            <FormProvider {...hook.form.assetForm}>
                <DeleteAssetForm>
                    
                        <div className={"menu-content"}>
                            <div className="content">
                                <div className="title">
                                    <FontGreyDarkMediumTitle>Deletar Ativo</FontGreyDarkMediumTitle>
                                </div>
                                <FontGreyDarkFullRegular>Tem certeza que deseja excluir o Ativo <FontGreyDarkFullBold> {selectedAsset?.name} - {selectedAsset?.code}</FontGreyDarkFullBold>?</FontGreyDarkFullRegular>
                            </div>

                            <div className="actions-container">
                                <SecondaryButton disabled={hook.form.isLoading} onClick={onClose}>Voltar</SecondaryButton>
                                <PrimaryButton disabled={hook.form.isLoading} onClick={hook.form.onSubmit}> {hook.form.isLoading ? 'Excluindo...' : 'Excluir'} </PrimaryButton>
                            </div>
                        </div>
            
                </DeleteAssetForm>
            </FormProvider>
        </ModalMain>
    );
}

export default DeleteAssetModal;