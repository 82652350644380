import { ModalMain } from "../styled";
import { FontGreyDarkFullRegular, FontGreyDarkMediumTitle } from "../../../../utils/typografyApp";
import SecondaryButton from "../../SecondaryButton";
import PrimaryButton from "../../PrimaryButton";
import { DeleteMediaForm } from "./styled";
import { useDeleteMediaModalHook } from "./hook";
import { FormProvider } from "react-hook-form";

const DeleteMediaModal = ({ onClose, selectedMedia, anomaly}) => {

    const hook = useDeleteMediaModalHook({ onClose, selectedMedia, anomaly });

    return (
        <ModalMain>
            <FormProvider {...hook.form.asset3DItemForm}>
                <DeleteMediaForm>
                    
                        <div className={"menu-content"}>
                            <div className="content">
                                <div className="title">
                                    <FontGreyDarkMediumTitle>Deletar Foto</FontGreyDarkMediumTitle>
                                </div>
                                <FontGreyDarkFullRegular>Tem certeza que deseja excluir esta foto?</FontGreyDarkFullRegular>
                            </div>

                            <div className="actions-container">
                                <SecondaryButton disabled={hook.form.isLoading} onClick={onClose}>Voltar</SecondaryButton>
                                <PrimaryButton disabled={hook.form.isLoading} onClick={hook.form.onSubmit}> {hook.form.isLoading ? 'Excluindo...' : 'Excluir'} </PrimaryButton>
                            </div>
                        </div>
            
                </DeleteMediaForm>
            </FormProvider>

            
           
        </ModalMain>
    );
}

export default DeleteMediaModal;