import { FontGreyDarkMediumTitle, FontGreyDarkRegular } from "../../../utils/typografyApp";
import { TopContainer, ActionsContainer, SectionInfoWindowContainer } from "./styled";
import CardGraphic from "../CardGraphic";
import SecondaryButton from "../SecondaryButton";
import GraphicStatus from "../GraphicStatus";
import { useSectionInfoWindowHook } from "./hook";
import { Canvas } from "react-three-fiber";
import { OrbitControls, PerspectiveCamera } from "@react-three/drei";
import { Loading } from "../Loading";

const key = Math.random();

const SectionInfoWindow = ({ section, asset3DItemID }) => {

    const hook = useSectionInfoWindowHook({ section, asset3DItemID });

    return (
        <SectionInfoWindowContainer>
            <TopContainer>
                <div className="local_3d">
                    {hook.section.isLoading && (
                        <div className="progress-bar">
                            <Loading />
                            <FontGreyDarkRegular><div className="progress-text"> Carregando: {Math.round(hook.config3d.progress)}%</div></FontGreyDarkRegular>
                        </div>
                    )}
                    <Canvas key={key}>
                        <ambientLight intensity={0.5} />
                        <directionalLight
                            position={[20, 10, 10]}
                            castShadow
                            shadow-mapSize-width={1024}
                            shadow-mapSize-height={1024}
                            shadow-camera-far={50}
                            shadow-camera-left={-10}
                            shadow-camera-right={10}
                            shadow-camera-top={10}
                            shadow-camera-bottom={-10}
                        />
                        <PerspectiveCamera ref={hook.config3d.camera} makeDefault position={[0.8, 0.01, 1.3]} />
                            <OrbitControls ref={hook.config3d.orbitControls} enableZoom={false} enableRotate={false} enableDamping={false} enablePan={false}/>
                        {hook.config3d.objects?.map((object, index) => (
                            object.object ?
                                <primitive
                                    key={index}
                                    object={object.object}
                                    onClick={(e) => {
                                        if (object.anomaly) {
                                            hook.general.setAnomalieWindow({
                                                isOpen: true,
                                                position: { x: e.pageX, y: e.pageY },
                                                content: object.anomaly
                                            });
                                        }
                                    }}

                                /> :
                                <></>
                        ))}
                    </Canvas>
                </div>
                {/* <img className="img-asset" src={section?.media?.path ?? default_section_image} alt="Imagem da seção"/> */}
                <FontGreyDarkMediumTitle>{section.name}</FontGreyDarkMediumTitle>
            </TopContainer>

            <CardGraphic
                title={"Status dos Ativos (m²)"}
                footer={{ description: "Área Total", value: hook.section.metricsSectionData?.status?.totalArea }}
                graphic={<GraphicStatus data={hook.section.metricsSectionData?.status} isLoading={hook.section.isLoading} />}

            />

            <ActionsContainer>
                {/* <SmallButton>
                    <img src={plus_icon} alt="Icone de adicionar" />
                </SmallButton> */}
                <SecondaryButton onClick={() => hook.general.navigate(`/section/${section.id}`)}>Detalhes da Seção</SecondaryButton>
            </ActionsContainer>
        </SectionInfoWindowContainer>
    );
}

export default SectionInfoWindow;