import { useQuery } from 'react-query';
import { useAssetsService, assetsQueries } from '../../../services/assetService';
import { useEffect } from 'react';
import { setMenuType, setShowDashboard } from '../../../store/actions/menu';

export function useHomeHook({setMenuType, setShowDashboard }){
    const assetService = useAssetsService();

    const {
        data : assetsData,
        isLoading : isLoadingAssets,
        isRefetching : isRefetchingAssets,
        refetch : refetchAssets
    } = useQuery(assetsQueries.GET_ALL, ()=>assetService.getAllAssets({search: '', page: 1}));

    useEffect(() => {
        setMenuType("MAP-PAGE");
        setShowDashboard();
    }, []);


    return{
        assets: {
            assetsData,
            isLoading: isLoadingAssets || isRefetchingAssets,
            refetchAssets
        }
    }
}