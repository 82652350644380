import { styled } from 'styled-components';

export const GraphicContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.825rem;
    align-self: stretch;
    min-height: 100px;
`;

export const GraphicItem = styled.div`
    display: flex;
    width: 1.5rem;
    flex-direction: column;
    align-items: center;
    gap: 0.25rem;

    .bar-config{
        width: 1rem;
        border-radius: 0.125rem;
    }

`

export const GraphicMaxBar = styled.div`
    position: relative;
    height: 64px;
    flex-shrink: 0;
    background: var(--grey-light-2-color);

    .ri1{
        background-color: var(--ri1-color);
    }
    .ri2{
        background-color: var(--ri2-color);
    }
    .ri3{
        background-color: var(--ri3-color);
    }
    .ri4{
        background-color: var(--ri4-color);
    }
    .ri5{
        background-color: var(--ri5-color);
    }
`;

export const GraphicBar = styled.div`
    height: ${(props) => {
        const maxPx = 64;
        const calculatedHeightPx = maxPx * props.height / props.total;
        return `${calculatedHeightPx}px`;
    }};
    position: absolute;
    bottom: 0;
`;