import { responseStatus, useHandleRequest } from "../service/api-services";
import {getDefaultQuery } from "../utils/query-client";

export const assets3dItemQueries = {
    EDIT: "ASSETS3DITEM_EDIT",
    CREATE: "ASSETS3DITEM_CREATE",
    DELETE: "ASSETS3DITEM_DELETE",
    GET_ALL: "ASSETS3DITEM_GET_ALL",
    GET_BY_ID: "ASSETS3DITEM_GET_BY_ID",
}


export function useAssets3DItemService() {

    const handleRequest = useHandleRequest();

    async function createAssets3DItem({ payload, onSuccess }) {
        const { status, data } = await handleRequest.handleRequest(`/assets-3d-item`, 'POST', payload);
        if (status === responseStatus.SUCCESS) {
            if (onSuccess) {
                onSuccess(data);
            }
        }
        console.log(data)
    }

    async function editAssets3DItem({ payload, onSuccess }) {
        const { status, data } = await handleRequest.handleRequest(`/assets-3d-item/${payload.id}`, 'PUT', payload);
        if (status === responseStatus.SUCCESS) {
            if (onSuccess) {
                onSuccess(data);
            }
        }
    }

    async function deleteAssets3DItem({ id, onSuccess }) {
        const { status, data } = await handleRequest.handleRequest(`/assets-3d-item/${id}`, 'DELETE');
        if (status === responseStatus.SUCCESS) {
            if (onSuccess) {
                onSuccess(data);
            }
        }
    }

    async function getAllAssets3DItem({ search, page }){
        const filters = [{search}];
        let query = getDefaultQuery({ page, filters });
        const { status, data } = await handleRequest.handleRequest(`/assets-3d-item/${query}`, 'GET');
        if (status === responseStatus.SUCCESS) {
            return data;
        }
    }

    async function getAssets3DItemById({ id }){
        const { status, data } = await handleRequest.handleRequest(`/assets-3d-item/${id}`, 'GET');
        if (status === responseStatus.SUCCESS) {
            return data;
        }
    }

    return {
        createAssets3DItem,
        editAssets3DItem,
        deleteAssets3DItem,
        getAllAssets3DItem,
        getAssets3DItemById,
    }
}
