import * as THREE from 'three';

export function formatPrice(value) {
    const options = {
        style: 'currency',
        currency: 'BRL' 
    };

    return value?.toLocaleString('pt-BR', options);
}

export function convertNumberBR(value){
   const converted =  parseFloat(value)
  .toFixed(2)
  .replace('.', ',')
  .replace(/\B(?=(\d{3})+(?!\d))/g, ".");

  return converted === "NaN" ? 0 : converted;
}

export function generateRandomId() {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let randomId = '';
    for (let i = 0; i < 8; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        randomId += characters[randomIndex];
    }
    return randomId;
}


export function convertPrice(value) {
    if(value){
        value = value.toString().replace(/[^\d,]/g, '');
        value = value.replace(',', '.');
    }
    return parseFloat(value);
}

export function checkCache(asset3DItem, prefix){
    const cachedItem = localStorage.getItem(`assets-${prefix}`);
    return cachedItem === asset3DItem?.token;
}

export function blobToArrayBuffer(blob){
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = () => reject(new Error('Failed to read blob'));
        reader.readAsArrayBuffer(blob);
    });
};

export function calculateBoundingBox(objects) {
    const box = new THREE.Box3();
    objects.forEach(({ object }) => {
        if (object) {
            box.expandByObject(object);
        }
    });
    return box;
}