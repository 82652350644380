import { useMutation } from "react-query";
import { queryClient } from "../../../../utils/query-client";
import { sectionsQueries } from "../../../../services/sectionService";
import { useMediasService } from "../../../../services/mediaService";

export function useDeleteMediaModalHook({onClose, selectedMedia, anomaly}){
    const mediaService = useMediasService();
    const { mutate, isLoading } = useMutation((payload) => mediaService.deleteMedias({id: selectedMedia.id, onSuccess}));


    function onSuccess() {
        anomaly.medias = anomaly.medias.filter(media=>media.id !== selectedMedia.id);
        onClose();
    }


    function onSubmit() {
        mutate();
    }

    return{
        form: {
            onSubmit,
            isLoading
        }
    }
}