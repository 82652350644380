export function useBarChartHook({ height, categories, minValue, maxValue, series }) {
    const options = {
        chart: {
            type: 'bar',
            height: height,
            zoom: {
                enabled: false,
            },
            events: {},
            toolbar: {
                show: false,
            },
        },

        tooltip: {
            enabled: false,
        },
        xaxis: {
            categories: categories,
            offsetY: -1,
            axisBorder: {
                show: false,
            },
            tooltip: {
                enabled: false,
            },
        },
        yaxis: {
            max: maxValue,
            min: minValue,
            labels: {
                show: false,
            },
            show: false,
        },

        grid: {
            show: false,
        },
        plotOptions: {
            bar: {
                horizontal: false,
                borderRadius: 2,
                columnWidth: '80%',
                endingShape: 'rounded',
                colors: {
                    backgroundBarColors: ['#f0f0f0'],
                    backgroundBarRadius: 4,
                }
            },
        },
        legend: {
            horizontalAlign: 'start', 
            markers: {
                size: 5,
                shape: 'square',
                strokeWidth: 4,
            },
        },
        dataLabels: {
            enabled: true,
            style: {
                fontSize: '8px',
                fontWeight: 'bold',
                colors: ['#000'],
            },
            background: {
                enabled: false,
            },
            formatter: (value) => {
                return value;
            },
        },
        fill: {
            opacity: 1,
        },
    };

    return {
        config: {
            options,
            series,
        },
    };
}
