import { ModalMain } from "../styled";
import { FontGreyDarkMediumTitle } from "../../../../utils/typografyApp";
import SecondaryButton from "../../SecondaryButton";
import PrimaryButton from "../../PrimaryButton";
import { NewAssetForm } from "./styled";
import { useNewAssetModalHook } from "./hook";
import Input from "../../Input";
import { FormProvider } from "react-hook-form";

const NewAssetModel = ({ onClose, selectedAsset3DItem }) => {

    const hook = useNewAssetModalHook({ onClose, selectedAsset: selectedAsset3DItem });

    return (
        <ModalMain>
            <FormProvider {...hook.form.assetForm}>
                <NewAssetForm>
                    <form onSubmit={hook.form.assetForm.handleSubmit(hook.form.onSubmit)}>
                        <div className={"menu-content"}>
                            <div className="content">
                                <div className="title">
                                    <FontGreyDarkMediumTitle>Edição de Ativo</FontGreyDarkMediumTitle>
                                </div>
                                {/* 
                                    <label className="profilePic" htmlFor="profilePic">
                                        <img src={profilePic || upload_input_icon} alt="Icone de Upload"/>
                                        <input 
                                            className="hidden-content" id="profilePic" type="file" 
                                            onChange={async (e)=> setProfilePic(await controller.onChangeFile(e))}
                                        />
                                    </label> 
                                */}

                                <div className="row">
                                    <Input type="text" placeholder="Nome do ativo" name="name" />
                                </div>

                                {/* <div className="row">
                                    <Select
                                        type="text"
                                        placeholder="Selecione a Unidade"
                                        name="unit"
                                        options={hook.units.unitsData}
                                    />

                                    <Select
                                        type="text"
                                        placeholder="Selecione o Grupo"
                                        name="group"
                                        options={groupOptions}
                                        selectedOption={asset.group}
                                        setSelectedOption={(selectedOption) => setAsset({ ...asset, group: selectedOption })}
                                        error={''}
                                    />
                                </div> */}

                                {/* <div className="row">
                                    <Input type="text" name='latStart' placeholder="Latitude" />
                                    <Input type="text" name='lngStart' placeholder="Longitude" />

                                    <div className="map">
                                        <input className="rot" type="text" name='rotation' placeholder="Rotação" />
                                        <img src={map_icon} alt="Icone ver no mapa" title={'Ver no mapa'} />
                                    </div>
                                </div>

                                <div className="row">
                                    <Input type="text" name='latEnd' placeholder="Latitude" />
                                    <Input type="text" name='lngEnd' placeholder="Longitude" />

                                    <div className="map">
                                        <input className="rot" type="text" name='rotation' placeholder="Rotação" />
                                        <img src={map_icon} alt="Icone ver no mapa" title={'Ver no mapa'} />
                                    </div>
                                </div> */}

                                <div className="row">
                                    {/* <Select
                                        type="text"
                                        placeholder="3D do Ativo"
                                        name="group"
                                        options={stlOptions}
                                        selectedOption={asset.model3d}
                                        setSelectedOption={(selectedOption) => setAsset({ ...asset, model3d: selectedOption })}
                                        error={''}
                                    /> */}
                                </div>

                            </div>

                            <div className="actions-container">
                                <SecondaryButton onClick={onClose} disabled={hook.form.isLoading}>Voltar</SecondaryButton>
                                <PrimaryButton type={'submit'} disabled={hook.form.isLoading}>{hook.form.isLoading ? 'Editando...' : 'Editar'}</PrimaryButton>
                            </div>
                        </div>
                    </form>
                </NewAssetForm>
            </FormProvider>
        </ModalMain>
    );
}

export default NewAssetModel;