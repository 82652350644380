import { connect } from "react-redux";
import { GoogleMap, Marker, Polyline } from "@react-google-maps/api";
import { MarkerContainer } from "./styled";
import { getSectionColor, StatusColor } from "../../../utils/colorsApp";
import { mapDispatchToProps, mapStateToProps } from "../../../utils/storeUtils";
import { useMapHook } from "./hook";
import { MapConfig } from "../../../utils/mapConfig";

import React from "react";
import Utils from "../../../utils/utils";
import CustomInfoWindow from "../CustomInfoWindow";
import AssetInfoWindow from "../AssetInfoWindow";

import pin_marker_icon from "../../../assets/icons/pin_marker.svg";
import invisible_pin_marker_icon from "../../../assets/icons/invisible_pin_marker.svg";

const Map = ({ map, assets, setZoom, zoom, selectedMarker = null }) => {

    const hook = useMapHook({ selectedMarker });
    console.log(assets)

    return (
        <div style={{ position: 'relative', height: '100%', width: '100%' }}>
            {hook.map.isLoaded && (
                <GoogleMap
                    mapContainerStyle={{ height: '100%', width: '100%' }}
                    center={hook.map.mapCenter}
                    zoom={selectedMarker ? 16 : zoom || map?.zoom || 16}
                    options={{
                        gestureHandling: selectedMarker ? 'none' : 'auto',
                        styles: MapConfig.mapStyles,
                        ...MapConfig.mapOptions,
                        restriction: {
                            latLngBounds: MapConfig.initialBounds,
                            strictBounds: true,
                        },
                    }}
                    
                    onZoomChanged={() => setZoom(hook.map.googleMap?.getZoom())}
                    onLoad={(map) => hook.map.setGoogleMap(map)}
                    draggable={!selectedMarker}
                >
                    {assets?.rows?.map((asset, index) => {
                        const basePosition = Utils.rotatePoint(asset.rotationAngle);
                        return (
                            <React.Fragment key={`asset-${index}`}>
                            
                                {!asset.latEnd ?
                                    <Marker
                                        onClick={selectedMarker ? () => { } : (e) => hook.map.setInfoWindow({ ...hook.map.infoWindow, isOpen: true, position: { x: e.domEvent.pageX, y: e.domEvent.pageY }, content: asset })}
                                        position={{ lat: asset.latStart, lng: asset.lngStart }}
                                        icon={{
                                            url: MapConfig.getMarkerAsset(asset.status, asset.rotationAngle),
                                            anchor: new window.google.maps.Point(basePosition.x, basePosition.y),
                                        }}
                                        label={{
                                            text: map?.showLabels ? asset.code : " ",
                                            fontSize: "0.625rem",
                                            fontWeight: "bold",
                                        }}
                                    /> :
                                    <React.Fragment>
                                        <Polyline
                                            onClick={selectedMarker ? () => { } : (e) => hook.map.setInfoWindow({ ...hook.map.infoWindow, isOpen: true, position: { x: e.domEvent.pageX, y: e.domEvent.pageY }, content: asset })}
                                            path={[
                                                { lat: asset.latStart, lng: asset.lngStart },
                                                { lat: asset.latEnd, lng: asset.lngEnd },
                                            ]}
                                            options={{ strokeColor: getSectionColor(asset.areaStatus), strokeWeight: 10 }}
                                        />
                                        
                                        {map?.showLabels && (
                                            <MarkerContainer>
                                                <Marker
                                                    onClick={selectedMarker ? () => { } : (e) => hook.map.setInfoWindow({ ...hook.map.infoWindow, isOpen: true, position: { x: e.domEvent.pageX, y: e.domEvent.pageY }, content: asset })}
                                                    zIndex={3001}
                                                    position={{
                                                        lat: (asset.latStart + asset.latEnd) / 2,
                                                        lng: (asset.lngStart + asset.lngEnd) / 2,
                                                    }}
                                                    label={{
                                                        text: asset.code,
                                                        fontSize: "0.625rem",
                                                        fontWeight: "normal",
                                                    }}
                                                    icon={{
                                                        url: invisible_pin_marker_icon,
                                                        anchor: new window.google.maps.Point(0, 0),
                                                    }}
                                                />
                                            </MarkerContainer>
                                        )}
                                        <Marker
                                            key={`point-${index}`}
                                            position={{ lat: asset.latEnd, lng: asset.lngEnd }}
                                            icon={{
                                                url: pin_marker_icon,
                                                scaledSize: new window.google.maps.Size(10, 10),
                                                origin: new window.google.maps.Point(0, 0),
                                                anchor: new window.google.maps.Point(5, 5),
                                            }}
                                        />

                                        <Marker
                                            key={`point-${index}`}
                                            position={{ lat: asset.latStart, lng: asset.lngStart }}
                                            icon={{
                                                url: pin_marker_icon,
                                                scaledSize: new window.google.maps.Size(10, 10),
                                                origin: new window.google.maps.Point(0, 0),
                                                anchor: new window.google.maps.Point(5, 5),
                                            }}
                                        />
                                    </React.Fragment>
                                }
                            </React.Fragment>
                        );
                    })}
                </GoogleMap>
            )}

            {hook.map.infoWindow.isOpen && (
                <CustomInfoWindow
                    position={hook.map.infoWindow.position}
                    onClose={() => hook.map.setInfoWindow({ ...hook.map.infoWindow, isOpen: false })}
                    height={590}
                    width={222}
                >
                    <AssetInfoWindow asset={hook.map.infoWindow.content} />
                </CustomInfoWindow>
            )}

            {/* Adiciona a overlay de destaque em torno do selectedMarker */}
            {selectedMarker && <div style={hook.map.highlightOverlayStyle} />}
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Map);
