import { ModalMain } from "../styled";
import arrow_bottom_icon from "../../../../assets/icons/arrow_bottom.svg"
import { FontGreyDarkMediumTitle, FontGreyDarkRegular, FontInputGreyDarkRegular, FontPrimaryMediumTitle } from "../../../../utils/typografyApp";
import SecondaryButton from "../../SecondaryButton";
import { FormContainer, RowContainer, SwitchContainer } from "./styled";
import Input from "../../Input";
import Select from "../../Select";
import SwitchButton from "../../SwitchButton";
import PrimaryButton from "../../PrimaryButton";
import success_icon from "../../../../assets/icons/success.svg";
import remove_icon from "../../../../assets/icons/remove.svg";
import { baseUrl } from "../../../../service/api";
import { useAnomalieHook } from "./hook";
import { FormProvider } from "react-hook-form";
import { Canvas } from "react-three-fiber";
import { OrbitControls, PerspectiveCamera } from "@react-three/drei";
import { Loading } from "../../Loading";
import DeleteMediaModal from "../DeleteMediaModal";

const key = Math.random();

const AnomalieModal = ({ onClose, setOpacity, section, anomaly, onCloseAnomalieWindow }) => {
    const hook = useAnomalieHook({ section, setOpacity, onClose, anomaly, onCloseAnomalieWindow });

    return (
        <ModalMain>
            <FormProvider {...hook.form.anomalieForm}>
                <form onSubmit={hook.form.anomalieForm.handleSubmit(hook.form.onSubmit)}>
                    <div className={hook.general.tab !== hook.general.tabs[0] && "hidden-content"}>
                        <div className="content">
                            <div className="title">
                                <FontGreyDarkMediumTitle>{anomaly ? 'Editar' : 'Registro de'} Anomalia</FontGreyDarkMediumTitle>
                                <img src={arrow_bottom_icon} alt="Icone de adicionar" />
                            </div>
                            <FormContainer>
                                {!section &&
                                    <RowContainer>
                                        <Select
                                            type="text"
                                            placeholder="Selecione a Área"
                                            name="fkSectionsId"
                                            options={hook.sections.sectionOptions}
                                            defaultValue={section.name}
                                        />
                                    </RowContainer>
                                }
                                <RowContainer>
                                    <Input type="number" placeholder="Área Degradada" name="area" />
                                </RowContainer>
                                <RowContainer>
                                    <Input type="text" placeholder="ID" name="internalId" />
                                    <Input type="date" placeholder="Data" name="date" />
                                </RowContainer>
                                <RowContainer>
                                    <Select
                                        type="text"
                                        placeholder="Tipo de Defeito"
                                        name="fkDefectTypeId"
                                        options={hook.defectTypes.defectTypesData}
                                        defaultValue={anomaly?.defectType?.name}
                                    />
                                    <Select
                                        type="text"
                                        placeholder="Grau de Degradação"
                                        name="fkDegradationDegreeId"
                                        options={hook.degradationDegree.degradationDegreeData}
                                        defaultValue={anomaly?.degradationDegree?.name}
                                    />
                                </RowContainer>
                                <RowContainer>
                                    <SwitchContainer>
                                        <FontInputGreyDarkRegular>Falha Prematura</FontInputGreyDarkRegular>
                                        <SwitchButton
                                            active={hook.form.watchPrematureFailure}
                                            toggleSelect={hook.form.handleSelectPrematureFailure}
                                        />
                                    </SwitchContainer>
                                </RowContainer>
                            </FormContainer>
                        </div>
                        <div className="actions-container">
                            <SecondaryButton onClick={onClose}>Voltar</SecondaryButton>
                            <PrimaryButton type="button" onClick={hook.form.validateForm}>Avançar</PrimaryButton>
                        </div>
                    </div>
                    <div className={`${hook.general.tab !== hook.general.tabs[1] && "hidden-content"}`} >
                        <div className="_3d">
                            {hook.config3d.isLoading && (
                                <div className="progress-bar">
                                    <Loading />
                                    <FontGreyDarkRegular><div className="progress-text"> Carregando: {Math.round(hook.config3d.progress)}%</div></FontGreyDarkRegular>
                                </div>
                            )}
                            <Canvas key={key}>
                                <ambientLight intensity={0.5} />
                                <directionalLight
                                    position={[20, 10, 10]}
                                    castShadow
                                    shadow-mapSize-width={1024}
                                    shadow-mapSize-height={1024}
                                    shadow-camera-far={50}
                                    shadow-camera-left={-10}
                                    shadow-camera-right={10}
                                    shadow-camera-top={10}
                                    shadow-camera-bottom={-10}
                                />
                                <PerspectiveCamera ref={hook.config3d.camera} makeDefault position={[-2, 1, 1]} />
                                <OrbitControls ref={hook.config3d.orbitControls} />
                                {hook.config3d.objects?.map((object, index) => (
                                    object.object ?
                                        <primitive
                                            key={index}
                                            object={object.object}
                                            onDoubleClick={hook.config3d.handleObjectClick}

                                        /> :
                                        <></>
                                ))}
                            </Canvas>
                        </div>
                        <div className="float-top">
                            <div className="content">
                                <div className="title underline">
                                    <FontGreyDarkMediumTitle>
                                        <FontPrimaryMediumTitle>Clique duas vezes </FontPrimaryMediumTitle>
                                        na {hook.selectedSection.selectedSection?.name} para {anomaly ? 'editar a posição de' : 'registrar'} uma anomalia
                                    </FontGreyDarkMediumTitle>
                                </div>
                            </div>
                            <div className="actions-container">
                                <SecondaryButton onClick={() => hook.general.setTab(hook.general.tabs[0])} >Voltar</SecondaryButton>
                                <PrimaryButton
                                    type="button"
                                    onClick={() => hook.general.setTab(hook.general.tabs[2])}
                                    disabled={!hook.config3d.sphere}
                                >
                                    Avançar
                                </PrimaryButton>
                            </div>
                        </div>
                    </div>

                    <div className={`add_image_container ${hook.general.tab !== hook.general.tabs[2] && "hidden-content"}`} >
                        <label to={"addImage"}>
                            <input id="addImage" type="file" accept=".jpg, .jpeg, .png" multiple name="medias" {...hook.form.anomalieForm.register('medias')} />
                            <div className="content">
                                <div className="title underline">
                                    <FontGreyDarkMediumTitle>
                                        <FontPrimaryMediumTitle>Adicine </FontPrimaryMediumTitle>
                                        fotos do local
                                    </FontGreyDarkMediumTitle>

                                </div>
                                <div className="images">
                                    {
                                        anomaly?.medias?.map(media => (
                                            <div className={'online_img'}>
                                                <img src={`${baseUrl}/${media.path}`} />
                                                <div className="remove_img" onClick={(e)=>{hook.handleSelectMedia(e, media)}}>
                                                    <img src={remove_icon} />
                                                </div>
                                            </div>
                                        ))
                                    }
                                    {
                                        Array.from(hook.form.watchMedias || [])?.map(media => (
                                            <div>
                                                <img src={URL.createObjectURL(media)} />
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </label>
                        <div className="actions-container">
                            <SecondaryButton onClick={() => hook.general.setTab(hook.general.tabs[1])}>Voltar</SecondaryButton>
                            <PrimaryButton type="submit" disabled={hook.form.isLoading || (!anomaly && hook.form.watchMedias && hook.form.watchMedias.length === 0)}>
                                {hook.form.isLoading ? 'Salvando...' : 'Concluir'}
                            </PrimaryButton>
                        </div>
                    </div>

                    <div className={`${hook.general.tab !== hook.general.tabs[3] && "hidden-content"}`} >
                        <div className="content">
                            <div className="title">
                                <FontGreyDarkMediumTitle>{anomaly ? "Registro de anomalia editado!" : "Novo registro de anomalia feito!"}</FontGreyDarkMediumTitle>
                                <img src={success_icon} alt="Icone de Sucesso" />
                            </div>
                        </div>
                    </div>
                </form>
            </FormProvider>

            {hook.showDeleteMedia && 
                <DeleteMediaModal 
                    selectedMedia={hook.showDeleteMedia} 
                    onClose={()=>hook.setShowDeleteMedia(null)}
                    anomaly={anomaly}
                />
            }

        </ModalMain >
    );
}

export default AnomalieModal;




