import { useMutation } from "react-query";
import { queryClient } from "../../../../utils/query-client";
import { useAnomaliesService } from "../../../../services/anomalieService";
import { sectionsQueries } from "../../../../services/sectionService";

export function useFinalizeAnomalieModalHook({onClose, selectedAnomalie}){
    const anomalyService = useAnomaliesService();
    const { mutate, isLoading } = useMutation((payload) => anomalyService.editAnomalies({payload, onSuccess}));


    function onSuccess() {
        queryClient.refetchQueries(sectionsQueries.GET_BY_ID);
        onClose();
    }


    function onSubmit() {
        const payload = {...selectedAnomalie, isResolved: true};
        delete payload.medias;
        mutate(payload);
    }

    return{
        form: {
            onSubmit,
            isLoading
        }
    }
}