import { useState } from "react";

export function useAnomalieInfoWindowHook(props){
    const [selectedImage, setSelectedImage] = useState('');
    const [showEditAnomaly, setShowEditAnomaly] = useState(false);
    const [showDeleteAnomaly, setShowDeleteAnomaly] = useState(false);
    const [showFinalizeAnomaly, setShowFinalizeAnomaly] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);

    const handleNext = () => {
        if (props.anomaly.medias && currentIndex < props.anomaly.medias.length - 1) {
            setCurrentIndex(currentIndex + 1);
        }
    };

    const handlePrev = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };
    
    return{
        selectedImage,
        showEditAnomaly,
        setShowEditAnomaly,
        showDeleteAnomaly,
        setShowDeleteAnomaly,
        showFinalizeAnomaly,
        setShowFinalizeAnomaly,
        setSelectedImage,
        handleNext,
        handlePrev,
        currentIndex
    }
}