import { styled } from "styled-components";

export const CardContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 0.5rem;
    

    .title-reports{
        margin-bottom: 1.4rem;
    }

    .footer-reports{
        margin-top: 1.4rem;
    }
`;

export const CardContent = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 0.5rem;
    height: ${props=>props.height}px !important;
`;

export const GraphicContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    max-height: 100%;
    height: ${props=>props.height}px !important;
`;

export const FooterContainer = styled.div`
    display: flex;
    padding: 0.25rem 0.5rem;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    border-radius: 8px;
    background: var(--grey-light-2-color);

`;


