import { useQuery } from 'react-query';
import { metricsQueries, useMetricsService } from '../../../../services/metricsService';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export function useCardReportAssetsHook() {
    const assetService = useMetricsService();
    const navigate = useNavigate();

    const [MlineYearChart, setMLineYearChart] = useState();


    const {
        data: singleMetricsData,
        isLoading: isLoadingSingleMetrics,
        isRefetching: isRefetchingSingleMetrics,
        refetch: refetchSingleMetrics
    } = useQuery(metricsQueries.GET_SINGLE_BY_CLIENT, () => assetService.getSingleByClient({
        // TODO Colocar dinamico
        fkClientId: 1

    }));


    const {
        data: generalMetricsData,
        isLoading: isLoadingMetrics,
        isRefetching: isRefetchingMetrics,
        refetch: refetchGeneralMetrics
    } = useQuery(metricsQueries.GET_ASSETS_GENERAL_BY_CLIENT, () => assetService.getAssetMetricsGeneralById({
        // TODO Colocar dinamico
        fkClientId: 1

    }));


    const {
        data: renovationMetricsData,
        isLoading: isLoadingRenovationMetrics,
        isRefetching: isRefetchingRenovationMetrics,
        refetch: refetchRenovationMetrics
    } = useQuery(metricsQueries.GET_ASSETS_RENOVATION_BY_CLIENT, () => assetService.getAssetsMetricsRenovationById({
        // TODO Colocar dinamico
        fkClientId: 1

    }));


    const {
        data: dueDegradationsMetricsData,
        isLoading: isLoadingDueDegradationsMetrics,
        isRefetching: isRefetchingDueDegradationsMetrics,
        refetch: refetchDueDegradationsMetrics
    } = useQuery(metricsQueries.GET_ASSETS_DUE_DEGRADATION_BY_CLIENT, () => assetService.getAssetsMetricsDueDegradationById({
        // TODO Colocar dinamico
        fkClientId: 1

    }));


    const {
        data: defectMetricsData,
        isLoading: isLoadingDefectMetrics,
        isRefetching: isRefetchingDefectMetrics,
        refetch: refetchDefectMetrics
    } = useQuery(metricsQueries.GET_ASSETS_DEFECT_TYPES_BY_CLIENT, () => assetService.getAssetsMetricsDefectTypesById({
        // TODO Colocar dinamico
        fkClientId: 1
    }));


    const {
        data: maturityMetricsData,
        isLoading: isLoadingMaturityMetrics,
        isRefetching: isRefetchingmatirityMetrics,
        refetch: refetchMaturityMetrics
    } = useQuery(metricsQueries.GET_ASSETS_MATURITY_BY_CLIENT, () => assetService.getAssetsMetricsMaturityById({
        // TODO Colocar dinamico
        fkClientId: 1

    }));


    useEffect(() => {
        const categories = maturityMetricsData?.lineYearData?.map(item => item.label) || [];
        const dataValues = maturityMetricsData?.lineYearData?.map(item => parseFloat(item.values[1] / 100).toFixed(2)) || [];

        const totalDataValues = [];
        maturityMetricsData?.lineYearData?.forEach(item => {
            totalDataValues.push(parseFloat(item.values[1] / 100).toFixed(2));
        });

        const maxValue = Math.max(...totalDataValues);
        const minValue = Math.min(...totalDataValues);

        setMLineYearChart({
            categories,
            series: [{
                name: "Passivo (m²)",
                color: "#ff4c00",
                data: dataValues
            }
            ],
            maxValue,
            minValue
        });
    }, [maturityMetricsData]);


    return {

        single: {
            metricsData: singleMetricsData,
            isLoading: isLoadingSingleMetrics || isRefetchingSingleMetrics,
            refetchSingleMetrics
        },

        assets: {
            metricsData: generalMetricsData,
            isLoading: isLoadingMetrics || isRefetchingMetrics,
            refetchGeneralMetrics
        },
        renovations: {
            metricsData: renovationMetricsData,
            isLoading: isLoadingRenovationMetrics || isRefetchingRenovationMetrics,
            refetchRenovationMetrics
        },
        dueDegradations: {
            metricsData: dueDegradationsMetricsData,
            isLoading: isLoadingDueDegradationsMetrics || isRefetchingDueDegradationsMetrics,
            refetchDueDegradationsMetrics
        },
        maturity: {
            MlineYearChart,
            metricsData: maturityMetricsData,
            isLoading: isLoadingMaturityMetrics || isRefetchingmatirityMetrics,
            refetchMaturityMetrics
        },

        defectTypes: {
            metricsData: defectMetricsData,
            isLoading: isLoadingDefectMetrics || isRefetchingDefectMetrics,
            refetchDefectMetrics
        },
        navigate

    }
}