import { useState } from "react";

export function useCardReportHook(){
    const tabItems = ['Produtividade', 'Ativos', 'Financeiro'];
    const [selectedTab, setSelectedTab] = useState('Produtividade');

    return{
        tabItems,
        selectedTab,
        setSelectedTab
    }

}