import Input from "../Input";
import { Content, ModalContainer, Card, CardContainer, DasboardContainer, EmptyContent, GyroscopeContainer } from "./styled";
import search_icon from "../../../assets/icons/search.svg";
import weather_icon from "../../../assets/icons/weather.svg";
import gyroscope_icon from "../../../assets/icons/gyroscope.svg";
import brush_icon from "../../../assets/icons/brush.svg";
import CardInformation from "./CardInformation";
import CardGraphic from "../CardGraphic";
import GraphicStatus from "../GraphicStatus";
import GraphicDegradation from "../GraphicDegradation";
import GraphicExpiration from "../GraphicExpiration";
import Menu from "./Menu";
import GuideMap from "../GuideMap";
import CardNotifications from "./CardNotifications";
import { connect } from "react-redux";
import { mapDispatchToProps, mapStateToProps } from "../../../utils/storeUtils";
import AssetInformation from "../AssetInformation";
import Utils from "../../../utils/utils";
import CardReports from "./CardReports";
import { useMenuHook } from "./hook";
import { LineChartComponent } from '../../components/LineChart';
import CardCalendar from "./CardCalendar";

const Dashboard = ({ menu, asset, section, guideMap = false, opacity = false }) => {
    const hook = useMenuHook({ asset, section });
    return (
        <ModalContainer opacity={opacity} id="dashboard">
            
            {menu.showNotifications && <CardNotifications showDashboard={hook.general.showDashboardCard} />}
            {menu.showConnections && <CardCalendar showDashboard={hook.general.showDashboardCard} />}
            {menu.showReports && <CardReports />}

            <DasboardContainer>
                <div className="scroll">
                    <Card showsashboardcard={hook.general.showDashboardCard ? "true" : "false"}>
                        <Menu />

                        {hook.general.showDashboardCard ?
                            <Content >
                                <Input className={"input-search"} type={"text"} placeholder={"Buscar ativo"} icon={search_icon} />
                                <div className="charts" onClick={() => hook.general.setShowDashboardCard(!hook.general.showDashboardCard)}>
                                    {
                                        asset || section ?
                                            <AssetInformation asset={asset || section} />
                                            :
                                            <CardContainer>
                                                <CardInformation title={"Corrosividade do Ambiente"} value={hook.chats?.dashboardData?.corrosivities[0]?.corrosivity} icon={weather_icon} />
                                                <CardInformation title={"Renovação média (m²/ano)"} value={hook.chats?.dashboardData?.corrosivities[0]?.count} icon={brush_icon} />
                                            </CardContainer>

                                    }

                                    <CardGraphic
                                        title={`Status ${asset ? "do Ativo" : section ? "da Seção" : "dos Ativos"} (m²)`}
                                        footer={{ description: "Área Total", value: hook.chats.dashboardData?.status?.totalArea }}
                                        graphic={<GraphicStatus data={hook.chats.dashboardData?.status} isLoading={hook.chats.isLoading} />}
                                    />

                                    <CardGraphic
                                        title={"Degradação de RI's (m²)"}
                                        footer={{ description: "Áreas em RI", value: hook.chats.dashboardData?.degradationRis?.total}}
                                        graphic={
                                            <GraphicDegradation data={hook.chats.dashboardData?.degradationRis} isLoading={hook.chats.isLoading} />
                                        }
                                    />
                                    {/* aqui123 */}
                                    <CardGraphic
                                        title={"Projeção de Vencimento (m²)"}
                                        graphic={
                                            <LineChartComponent 
                                                width={'200px'}
                                                height={180} 
                                                {...hook.chats.data}
                                                isLoading={hook.chats.isLoading}
                                            />
                                        }
                                    />
                                </div>
                            </Content> :
                            <EmptyContent onClick={() => hook.general.setShowDashboardCard(!hook.general.showDashboardCard)} />
                        }
                    </Card>
                </div>

                {guideMap ?
                    <GuideMap /> :
                    <></>
                }
            </DasboardContainer>
        </ModalContainer>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);