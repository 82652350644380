import { useEffect, useRef, useState } from "react";
import Utils from "../../../../utils/utils";
import * as THREE from 'three';
import { STLLoader } from 'three/addons/loaders/STLLoader';
import { blobToArrayBuffer, calculateBoundingBox } from "../../../../utils/functions";
import { getFromIndexedDB } from "../../../../service/indexedDB";

export function useViewSTLAssetModalHook({ asset }) {
    const [objects, setObjects] = useState([]);
    const [highlightedObject, setHighlightedObject] = useState(null);
    const [refresh, setRefresh] = useState(true);
    const [progress, setProgress] = useState(0);
    const [realProgress, setRealProgress] = useState(0);
    const orbitControls = useRef();
    const camera = useRef();

    const colors = ['#cecece'];

    useEffect(()=>{
        if(progress > realProgress){
            setRealProgress(progress);
        }
    }, [progress]);


    useEffect(() => {
        async function loadSections() {
            if (asset?.sections && asset.sections.length > 0) {
                Utils.clear3DObjects(objects, setObjects);
        
                const tempObjects = [];
                const loader = new STLLoader();
        
                let totalSections = asset.sections.length;
                let loadedSections = 0;

                const downloadPromises = asset.sections.map(async (section) => {
                    if (section.location) {
                        const relativePaths = section.location.split('/')
                        const cacheKey = `${relativePaths[relativePaths.length - 1]}`;
                        try {
                            // Tentar recuperar do IndexedDB
                            const cachedDataBlob = await getFromIndexedDB(cacheKey, `admin-${asset?.id}`);
                    
                            if (cachedDataBlob) {
                                // Converter Blob para ArrayBuffer
                                const arrayBuffer = await blobToArrayBuffer(cachedDataBlob);
                                const geometry = loader.parse(arrayBuffer);
                                const material = new THREE.MeshStandardMaterial({ color: colors[colors.length % colors.length] });
                                const stlMesh = new THREE.Mesh(geometry, material);
        
                                stlMesh.castShadow = true;
                                stlMesh.receiveShadow = true;
        
                                loadedSections++;
                                setProgress((loadedSections / totalSections) * 100);

                                return { section, object: stlMesh };
                            } else {
                                return { section, object: undefined }; 
                            }
                        } catch (error) {
                            console.error(`Failed to load STL file for section ${section.token}:`, error);
                            return { section, object: undefined };
                        }
                    } else {
                        return { section, object: undefined };
                    }
                });
        
                try {
                    const results = await Promise.all(downloadPromises);
                    tempObjects.push(...results);
                    setObjects([...tempObjects]);
                } catch (error) {
                    console.error('Error loading sections:', error);
                }
                
                setRefresh(false);
            }
        }

        loadSections();
        
    }, [asset]);

    useEffect(() => {
        if (!refresh && objects.length > 0 && !!orbitControls.current && camera.current) {
            // Calcular a caixa delimitadora do conjunto de objetos
            const boundingBox = calculateBoundingBox(objects);
            const center = new THREE.Vector3();
            boundingBox.getCenter(center);

            const size = new THREE.Vector3();
            boundingBox.getSize(size);
            const maxDim = Math.max(size.x, size.y, size.z);
            const scaleFactor = 1 / maxDim;

            // Escalar todos os objetos
            objects.forEach(({ object }) => {
                if (object) {
                    object.scale.set(scaleFactor, scaleFactor, scaleFactor);
                    object.position.sub(center).multiplyScalar(scaleFactor);
                }
            });

            // Atualizar a posição da câmera e os controles
            orbitControls.current.target.set(0, 0, 0);
            camera.current.position.set(0, 0, size.z * scaleFactor);
            orbitControls.current.update();
            
        }
    }, [refresh, objects]);

    return {
        config3d: {
            orbitControls,
            objects,
            highlightedObject,
            colors,
            isLoading: refresh,
            progress :  realProgress,
            camera,
        }
    };
}
