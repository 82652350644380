import { ModalMain } from "../styled";
import { FontGreyDarkMediumTitle } from "../../../../utils/typografyApp";
import SecondaryButton from "../../SecondaryButton";
import PrimaryButton from "../../PrimaryButton";
import { NewAsset3DForm } from "./styled";
import Input from "../../Input";
import { useNewAsset3DItemModalHook } from "./hook";
import { FormProvider } from "react-hook-form";

const NewAsset3DModal = ({ onClose, selectedAsset3DItem }) => {

    const hook = useNewAsset3DItemModalHook({ onClose, selectedAsset3DItem });

    return (
        <ModalMain>
            <FormProvider {...hook.form.asset3DItemForm}>
                <NewAsset3DForm>
                    <form onSubmit={hook.form.asset3DItemForm.handleSubmit(hook.form.onSubmit)}>
                        <div className={"menu-content"}>
                            <div className="content">
                                <div className="title">
                                    <FontGreyDarkMediumTitle>{selectedAsset3DItem ? 'Editar ' : 'Cadastro '} de 3D do Ativo</FontGreyDarkMediumTitle>
                                </div>
                                <div className="form-input">
                                    <Input type="text" placeholder="Nome do modelo de ativo" name="name" />
                                </div>
                            </div>

                            <div className="actions-container">
                                <SecondaryButton disabled={hook.form.isLoading} onClick={onClose}>Voltar</SecondaryButton>
                                <PrimaryButton disabled={hook.form.isLoading} type={'submit'}> {hook.form.isLoading ? 'Salvando...' : selectedAsset3DItem ? 'Editar' : 'Criar'} </PrimaryButton>
                            </div>
                        </div>
                    </form>
                </NewAsset3DForm>
            </FormProvider>
        </ModalMain>
    );
}

export default NewAsset3DModal;