import { useEffect } from "react";
import { Main } from "../../main-styled";
import { PageContainer } from "./styled";
import { mapDispatchToProps, mapStateToProps } from "../../../utils/storeUtils";
import { connect } from "react-redux";
import { useHomeHook } from "./hook";

import Dashboard from "../../components/Dashboard";
import Guide from "../../components/Guide";
import Map from "../../components/Map";

const HomePage = ({ setMenuType, setShowDashboard }) => {
    const hook = useHomeHook({setMenuType, setShowDashboard});

    return (
        <Main>
            <PageContainer>
                <div className="map">
                    <Map assets={hook.assets.assetsData} />
                </div>

                <Guide />

                {hook.assets.assetsData?.count !== 0 &&
                    <Dashboard guideMap={true} />
                }
            </PageContainer>
        </Main>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);