import { useEffect, useRef, useState } from "react";
import Utils from "../../../../utils/utils";
import * as THREE from 'three';
import { STLLoader } from 'three/addons/loaders/STLLoader';
import { baseUrl } from "../../../../service/api";
import { blobToArrayBuffer, calculateBoundingBox } from "../../../../utils/functions";
import { getFromIndexedDB, saveToIndexedDB } from "../../../../service/indexedDB";
import axios from "axios";

export function useViewSTLModalHook({ section, asset3DItemId }) {
    const [objects, setObjects] = useState([]);
    const [highlightedObject, setHighlightedObject] = useState(null);
    const [refresh, setRefresh] = useState(true);
    const [progress, setProgress] = useState(0);
    const orbitControls = useRef();
    const camera = useRef();

    const colors = ['#cecece'];

    useEffect(() => {
        if (section) {
            Utils.clear3DObjects(objects, setObjects);

            const tempObjects = [];

            if (section.location) {
                const loader = new STLLoader();

                const loadSTL = async () => {
                    try {
                        const relativePaths = section.location.split('/')
                        const cacheKey = `${relativePaths[relativePaths.length - 1]}`;
                        let arrayBuffer;


                        const cachedDataBlob = await getFromIndexedDB(cacheKey, `admin-${asset3DItemId}`);

                        if (cachedDataBlob) {
                            arrayBuffer = await blobToArrayBuffer(cachedDataBlob);
                        } else {
                            const response = await axios.get(`${baseUrl}/${section.location}`, {
                                responseType: 'arraybuffer',
                                onDownloadProgress: (progressEvent) => {
                                    setProgress((progressEvent.loaded / progressEvent.total) * 100);
                                }
                            });
                            
                            arrayBuffer = response.data;
                            
                            await saveToIndexedDB(cacheKey, new Blob([arrayBuffer]), `admin-${asset3DItemId}`);
                        }

                        if (arrayBuffer) {
                            const geometry = loader.parse(arrayBuffer);
                            const box = new THREE.Box3().setFromObject(new THREE.Mesh(geometry));
                            const center = new THREE.Vector3();
                            box.getCenter(center);

                            const size = new THREE.Vector3();
                            box.getSize(size);
                            const maxDim = Math.max(size.x, size.y, size.z);

                            const scaleFactor = (1 / maxDim);
                            geometry.scale(scaleFactor, scaleFactor, scaleFactor);

                            const material = new THREE.MeshStandardMaterial({ color: colors[colors.length % colors.length] });
                            const stlMesh = new THREE.Mesh(geometry, material);
                            stlMesh.position.set(-center.x * scaleFactor, -center.y * scaleFactor, -center.z * scaleFactor);
                            stlMesh.castShadow = true;
                            stlMesh.receiveShadow = true;

                            tempObjects.push(
                                { section: section, object: stlMesh },
                            );

                            setObjects(tempObjects);

                            setRefresh(false);
                        }
                    } catch (error) {
                        console.error(`Failed to load STL file for section ${section.token}:`, error);
                    }
                };

                loadSTL();
            }
        }
    }, [section]);

    return {
        config3d: {
            orbitControls,
            objects,
            highlightedObject,
            colors,
            isLoading: refresh,
            progress,
            camera
        }
    };
}
