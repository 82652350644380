import styled from "styled-components";

export const ReportsContainer = styled.div`
    position: fixed;
    display: flex;
    flex-direction: column;
    background-color: var(--white-color);
    width: calc(100% - 26rem);
    height: calc(100vh - 2rem);
    left: 2rem;
    top: 1rem;
    overflow-y: scroll !important;
    border-radius: 0.5rem;

    .border{
        box-sizing: border-box;
        border: 1px solid var(--grey-light-2-color);
        border-radius: 0.5rem;
        padding: 1rem;

        .graphicRadarContainer{
            margin: 0 4rem;
        }

        .graphicRadarMapContainer{
            width: 100%;
            height: 100%;
            border-radius: 1rem;
            overflow: hidden;
        }
    }
`;

export const TabSelect = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    gap: 0.5rem;
    background-color: var(--grey-light-2-color);
    margin: 1rem;
    height: 2rem;
    border-radius: 0.5rem;
    padding: 0.5rem;
`;

export const TabItem = styled.div`
    background-color: ${props=>props.active === "true" ? "var(--primary-color)" : "var(--grey-light-2-color)" };
    color: ${props=>props.active === "true" ? "var(--white-color)" : "var(--grey-medium-color)" };
    padding: 0.5rem;
    border-radius: 0.5rem;
    cursor: pointer;

    &:hover{
        background-color: var(--primary-color);
        color: var(--white-color);
    }
`;

export const PaintingPlanningContainer = styled.div`

`;

export const AnalyzeAnticorrosiveContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100% !important;
`;

export const ActionsContainer = styled.div`
    display: flex;
    gap: 0.25rem;
    margin-top: 0.8rem;

    .secondary-button{
        max-width: 100% !important;
    }

    button{
        height: 2rem !important;
    }

`;

export const Row = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 1rem;

    .width-20{
        width: 20% !important;
    }

    .width-40{
        width: 40% !important;
    }

    .full{
        width: 100%;
        @media screen and (max-width: 1600px){
            width: 98%;
        }
    }

    .long{
        width: 780px;
    }
    .small{
        width: 140px;
    }
    .small-3{
        width: 32.5%;
    }
    .medium{
        width: 50%;
    }

    

    .mt{
        margin-top: 1.5rem;
    }

    .ml{
        margin-left: 1rem;
    }
`;

export const TotalAreaContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid var(--grey-light-2-color);
    padding: 3% 1%;
    gap: 0.5rem;
    border-radius: 0.5rem;   

    .map-container{
        width: 99%;
        height: 16.8rem;
        border-radius: 0.5rem;
        overflow: hidden;

        @media screen and (max-width: 1600px){
            height: 17.6rem;
        }
    }

    .count-info{
        display: flex;
        padding: 0.25rem 1rem !important;
        align-items: center;
        justify-content: space-between;
        width: calc(100% - 2rem);
        background-color: var(--grey-light-2-color);
        border-radius: 0.5rem;
    }
`;

export const OtherInfo = styled.div`
    display: flex;
    padding: 0.5rem 1rem;
    width: calc(100% - 2rem);
    align-items: center;
    justify-content: space-between;
    border: 1px solid var(--grey-light-2-color);
    border-radius: 0.5rem;
    div{
        display: flex;
        gap: 1rem;
        align-items: center;
    }
`;

export const Column = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    box-sizing: border-box;
   
`;

export const GraphicContainer = styled.div`
    width: 100%;
    padding: 1% 1rem !important;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
    box-sizing: border-box;

    .loading{
        height: 500px;
        width: 100%;
    }
`;


export const PaintingPlaneContainer = styled.div`
    margin-top: 18px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const SCurveContainer = styled.div`
    margin-top: 18px;
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const JoinedPlanContainer = styled.div`
    margin-top: 18px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .second-chart{
        margin-top:  -18px;
    }
`;

export const OpexContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const PaintCostsContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;
