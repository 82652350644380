import { FontGreyDarkMediumTitle } from '../../../../utils/typografyApp';
import CardGraphic from '../../CardGraphic';
import { AnalyzeAnticorrosiveContainer, Column, GraphicContainer, JoinedPlanContainer, PaintingPlaneContainer, Row, SCurveContainer } from './styled';

import { useCardReportProdutivityHook } from './hook';
import { LineChartComponent } from '../../LineChart';
import { BarChartComponent } from '../../BarChart';
import { Loading } from '../../Loading';


const CardReportProdutivity = () => {


    const hook = useCardReportProdutivityHook();

    return (
        <>
            <GraphicContainer>
                {!hook.produtivity.isLoading ?
                    <>
                        <FontGreyDarkMediumTitle>Analise longo prazo tratamento Anticorrosivo (m²)</FontGreyDarkMediumTitle>
                        <AnalyzeAnticorrosiveContainer>
                            <Row>
                                <Column className='long'>
                                    <CardGraphic
                                        height={160}
                                        graphic={
                                            <LineChartComponent
                                                height={160}
                                                width={"740px"}
                                                {...hook.produtivity.ACTLineChart}
                                                isLoading={hook.produtivity.isLoading}
                                            />
                                        }
                                    />

                                    <CardGraphic
                                        height={100}
                                        graphic={
                                            <BarChartComponent
                                                height={100}
                                                width={"740px"}
                                                color={'#0C8CE9'}
                                                {...hook.produtivity.ACTBarYears}

                                                hiddenLegend={true}
                                                isLoading={hook.produtivity.isLoading}
                                            />
                                        }
                                    />
                                </Column>
                                <Column className='small'>
                                    <CardGraphic
                                        graphic={
                                            <BarChartComponent
                                                height={293}
                                                width={"140px"}
                                                color={'#0C8CE9'}
                                                {...hook.produtivity.ACTBarChart}
                                                isLoading={hook.produtivity.isLoading}
                                            />
                                        }
                                    />
                                </Column>
                            </Row>
                        </AnalyzeAnticorrosiveContainer>

                        <PaintingPlaneContainer>
                            <FontGreyDarkMediumTitle>Planejamento de pintura anual (m²)</FontGreyDarkMediumTitle>
                            <Row>
                                <Column className='long'>
                                    <CardGraphic
                                        height={320}
                                        graphic={
                                            <LineChartComponent
                                                height={300}
                                                width={"740px"}
                                                {...hook.produtivity.APPLineChart}
                                                isLoading={hook.produtivity.isLoading}
                                            />
                                        }
                                    />
                                </Column>
                                <Column className='small'>
                                    <CardGraphic
                                        graphic={
                                            <BarChartComponent
                                                height={293}
                                                width={"140px"}
                                                color={'#0C8CE9'}
                                                {...hook.produtivity.APPBarChart}
                                                isLoading={hook.produtivity.isLoading}
                                            />
                                        }
                                    />
                                </Column>
                            </Row>
                        </PaintingPlaneContainer>

                        <Row>
                            <SCurveContainer>
                                <FontGreyDarkMediumTitle>Curva S de Avanço</FontGreyDarkMediumTitle>
                                <Row>
                                    <Column className='long'>
                                        <CardGraphic
                                            graphic={
                                                <LineChartComponent
                                                    height={260}
                                                    width={"420px"}
                                                    {...hook.produtivity.SCMLineChart}
                                                    isLoading={hook.produtivity.isLoading}
                                                />
                                            }
                                        />
                                    </Column>


                                </Row>

                            </SCurveContainer>

                            <Column className='full'>
                                <JoinedPlanContainer>
                                    <FontGreyDarkMediumTitle>Aderencia do Plano Anual dos Ativos (m²)</FontGreyDarkMediumTitle>
                                    <Row>
                                        <Column className='full'>
                                            <CardGraphic
                                                graphic={
                                                    <LineChartComponent
                                                        height={hook.produtivity.metricsData.adherenceToTheAnnualPaintingPlanMetrics.barYears.length > 0 ? 100 : 260}
                                                        width={"410px"}
                                                        {...hook.produtivity.AAPLineChart}
                                                        isLoading={hook.produtivity.isLoading}
                                                    />
                                                }
                                            />
                                        </Column>
                                    </Row>
                                    {hook.produtivity.metricsData.adherenceToTheAnnualPaintingPlanMetrics.barYears.length > 0 &&
                                        <Row className='second-chart'>
                                            <Column className='full'>
                                                <CardGraphic
                                                    graphic={
                                                        <BarChartComponent
                                                            height={100}
                                                            width={"410px"}
                                                            color={'#0C8CE9'}
                                                            {...hook.produtivity.AAPBarYears}
                                                            isLoading={hook.produtivity.isLoading}
                                                        />
                                                    }
                                                />
                                            </Column>
                                        </Row>
                                    }


                                </JoinedPlanContainer>
                            </Column>
                        </Row>
                    </> :
                    <div className='loading'>
                        <Loading />
                    </div>
                }
            </GraphicContainer>

        </>
    );
}

export default CardReportProdutivity;
