import { useEffect, useState } from 'react';
import Guide from '../../Guide';
import { AnalyzeAnticorrosiveContainer, Column, ActionsContainer, GraphicContainer, JoinedPlanContainer, OpexContainer, OtherInfo, PaintCostsContainer, PaintingPlaneContainer, ReportsContainer, Row, SCurveContainer, TabItem, TabSelect, TotalAreaContainer } from './styled';
import { FontGreyDarkMedium, FontGreyDarkMediumTitle, FontGreyDarkRegular, FontGreyDarkSemiMedium, FontGreyMediumSemiMedium, FontPrimaryMediumTitle, FontPrimaryMegaTitle, FontPrimarySuperTitle } from '../../../../utils/typografyApp';
import CardGraphic from '../../CardGraphic';
import GraphicExpiration from '../../GraphicExpiration';
import GraphicBars from '../../GraphicBars';
import GraphicAcum from '../../GraphicAcum';
import GraphicRadar from '../../GraphicRadar';
import plus_icon from '../../../../assets/icons/plus.svg';
import Map from "../../Map";
import GraphicQuantitative from '../../GraphicQuantitative';
import GraphicStatusColumn from '../../GraphicStatusColumn';
import GraphicBarsClass from '../../GraphicBarsClass';
import GraphicQuantitativeSmall from '../../GraphicQuantitativeSmall';
import api from '../../../../service/api';
import card_1_icon from '../../../../assets/icons/card-1.svg';
import card_2_icon from '../../../../assets/icons/card-2.svg';
import card_3_icon from '../../../../assets/icons/card-3.svg';
import card_4_icon from '../../../../assets/icons/card-4.svg';
import AssetInformation from '../../AssetInformation';
import Utils from '../../../../utils/utils';
import GraphicStatus from '../../GraphicStatus';
import GraphicDegradation from '../../GraphicDegradation';
import SmallButton from '../../SmallButton';
import SecondaryButton from '../../SecondaryButton';
import { Loading } from '../../Loading';

import { useCardReportAssetsHook } from './hook';
import { convertNumberBR } from '../../../../utils/functions';
import { LineChartComponent } from '../../LineChart';


const CardReportAssets = () => {


    const hook = useCardReportAssetsHook();



    return (
        <>
            <GraphicContainer>
                <Row>
                    <Column className='small-3'>
                        <TotalAreaContainer>
                            <FontGreyDarkMedium>Área Total da Planta</FontGreyDarkMedium>
                            <FontPrimaryMegaTitle>{convertNumberBR(hook.renovations.metricsData?.totalArea)} m²</FontPrimaryMegaTitle>
                            <div className='map-container'>
                                <Map zoom={8} />
                            </div>
                            <div className='count-info'>
                                <FontGreyDarkSemiMedium>Total de ativos</FontGreyDarkSemiMedium>
                                <FontPrimaryMegaTitle>{hook.renovations.metricsData?.countAssets}</FontPrimaryMegaTitle>
                            </div>

                        </TotalAreaContainer>
                    </Column>
                    <Column className='small-3'>
                        <GraphicQuantitative
                            title={{
                                value: hook.renovations.metricsData?.totalExpiredData.count,
                                description: "Ativos com área vencida",
                                color: "#ff0000"
                            }}

                            subtitle={{
                                value: convertNumberBR(hook.renovations.metricsData?.totalExpiredData.totalArea),
                                description: "Área (m²)",
                                color: "#878787"
                            }}
                        />

                        {!hook.single.isLoading && hook.single.metricsData?.status ?
                            <GraphicStatusColumn
                                title={"Status dos Ativos (m²)"}
                                config={[
                                    { color: "#48DF45" },
                                    { color: "#FFAB01" },
                                    { color: "#FF0000" }
                                ]}
                                data={{
                                    total: ([
                                        hook.single.metricsData?.status.currentActiveArea,
                                        hook.single.metricsData?.status.currentUpComingArea,
                                        hook.single.metricsData?.status.currentExpiredArea,
                                    ].sort((a, b) => b - a)[0] * 1.5),

                                    values: [
                                        hook.single.metricsData?.status.currentActiveArea,
                                        hook.single.metricsData?.status.currentUpComingArea,
                                        hook.single.metricsData?.status.currentExpiredArea,
                                    ]
                                }}
                                legend={{
                                    label: "Área total",
                                    value: hook.single.metricsData?.status.totalArea
                                }}
                            />
                            : <Loading size={30} />
                        }


                        <OtherInfo>
                            <FontGreyDarkRegular>Passivo</FontGreyDarkRegular>
                            <div>
                                <FontPrimarySuperTitle>{convertNumberBR(hook.renovations.metricsData?.totalAreaPassivo)}</FontPrimarySuperTitle>
                                <FontGreyDarkRegular>m²</FontGreyDarkRegular>
                            </div>
                        </OtherInfo>

                    </Column>
                    <Column className='small-3'>
                        <GraphicQuantitative
                            title={{
                                value: hook.renovations.metricsData?.totalDegradedData.count,
                                description: "Ativos com degradação",
                                color: "#770101"
                            }}

                            subtitle={{
                                value: convertNumberBR(hook.renovations.metricsData?.totalDegradedData.totalArea),
                                description: "Área (m²)",
                                color: "#878787"
                            }}
                        />

                        {!hook.single.isLoading && hook.single.metricsData?.degradationRis ?

                            <GraphicStatusColumn
                                title={"Degradação de RI's (m²)"}
                                config={[
                                    { color: "#FF7A00" },
                                    { color: "#FF4D00" },
                                    { color: "#FF0000" },
                                    { color: "#BF0000" },
                                    { color: "#770101" },
                                ]}
                                data={{
                                    total: hook.single.metricsData?.degradationRis.total,

                                    values: hook.single.metricsData?.degradationRis.ris.map(e => e.totalArea)
                                }}
                                legend={{
                                    label: "Áreas em RI",
                                    value: hook.single.metricsData?.degradationRis.total
                                }}
                            /> : <Loading size={30} />}


                        <OtherInfo>
                            <FontGreyDarkRegular>Parque instalado</FontGreyDarkRegular>
                            <div>
                                <FontPrimarySuperTitle>{convertNumberBR(hook.renovations.metricsData?.parqueInstalado)}%</FontPrimarySuperTitle>
                            </div>
                        </OtherInfo>
                    </Column>

                </Row>
                <GraphicContainer>
                    <Row>

                        <Column className='medium mt'>
                            <Row>
                                <GraphicQuantitativeSmall
                                    title={{
                                        description: "Taxa de renovação - Teórico",
                                        color: "#292929"
                                    }}

                                    icon={card_4_icon}

                                    subtitle={{
                                        value: convertNumberBR(hook.single.metricsData?.renovation.weightedAverageTeorical),
                                        description: "m²/ano",
                                        color: "#0C8CE9"
                                    }}
                                />
                                <GraphicQuantitativeSmall
                                    title={{
                                        description: "Taxa de renovação - Real",
                                        color: "#292929"
                                    }}

                                    icon={card_3_icon}

                                    subtitle={{
                                        value: convertNumberBR(hook.single.metricsData?.renovation.weightedAverageReal),
                                        description: "m²/ano",
                                        color: "#0C8CE9"
                                    }}
                                />
                            </Row>

                            <Row>
                                <GraphicQuantitativeSmall
                                    title={{
                                        description: "Vida útil média da pintura",
                                        color: "#292929"
                                    }}

                                    icon={card_1_icon}

                                    subtitle={{
                                        value: convertNumberBR(hook.single.metricsData?.renovation.averageLifetime),
                                        description: "anos",
                                        color: "#0C8CE9"
                                    }}
                                />
                                {
                                    !hook.single.isLoading && hook.single.metricsData?.corrosivities ?
                                        <GraphicQuantitativeSmall
                                            title={{
                                                description: "Corrosividade",
                                                color: "#292929"
                                            }}

                                            icon={card_2_icon}

                                            subtitle={{
                                                value: hook.single.metricsData?.corrosivities.length > 0 ? hook.single.metricsData?.corrosivities[0]?.corrosivity : 'N/A',
                                                description: "",
                                                color: "#0C8CE9"
                                            }}
                                        /> : <Loading size={30} />
                                }
                            </Row>
                        </Column>

                        <Column className='medium ml mt'>
                            <CardGraphic
                                height={197}
                                graphic={
                                    <LineChartComponent
                                        height={197}
                                        type="area"
                                        width={"410px"}
                                        {...hook.maturity.MlineYearChart}
                                        isLoading={hook.maturity.isLoading}
                                    />
                                }
                            />
                        </Column>
                    </Row>
                </GraphicContainer>
                <GraphicContainer>
                    <Row>

                        <Column className='medium'>
                            <FontGreyDarkMediumTitle>Vencimento de pintura</FontGreyDarkMediumTitle>
                            <CardGraphic
                                graphic={
                                    !hook.dueDegradations.isLoading && hook.dueDegradations.metricsData.groupedAtivosDue ?

                                        <GraphicBarsClass

                                            data={
                                                hook.dueDegradations.metricsData.groupedAtivosDue
                                            }
                                        // data={[
                                        //     { label: "Parainer I", value: 2300, total: 3200 },
                                        //     { label: "Parainer II", value: 2800, total: 3200 },
                                        //     { label: "Parainer III", value: 2300, total: 3200 },
                                        //     { label: "Parainer IV", value: 1300, total: 3200 },
                                        // ]}
                                        /> :
                                        <Loading size={30} />}
                            />
                        </Column>

                        <Column className='medium'>
                            <FontGreyDarkMediumTitle>Classe de degradação</FontGreyDarkMediumTitle>
                            <CardGraphic
                                graphic={

                                    !hook.dueDegradations.isLoading && hook.dueDegradations.metricsData.groupedAtivosDegraded ?
                                        <GraphicBarsClass

                                            data={
                                                hook.dueDegradations.metricsData.groupedAtivosDegraded
                                            }
                                        // data={[
                                        //     { label: "Parainer I", value: 2300, total: 3200 },
                                        //     { label: "Parainer II", value: 3000, total: 3200 },
                                        //     { label: "Parainer III", value: 2300, total: 3200 },
                                        //     { label: "Parainer IV", value: 2000, total: 3200 },
                                        // ]}
                                        /> :
                                        <Loading size={30} />}
                            />
                        </Column>
                    </Row>
                </GraphicContainer>
                <GraphicContainer>
                    <Row className='border'>
                        <Column className="medium">
                            <Column>
                                <FontGreyDarkMediumTitle>Modos de falha</FontGreyDarkMediumTitle>
                                <div className='graphicRadarContainer'>
                                    <CardGraphic
                                        graphic={
                                            !hook.defectTypes.isLoading && hook.defectTypes.metricsData.seriesNames ?
                                                <GraphicRadar
                                                    config={[
                                                        { label: 'Falha geral', color: 'RGBA(168, 168, 168, 0.2)', borderColor: '#A8A8A8' },
                                                        { label: 'Seleção', color: 'RGBA(255, 0, 0, 0.4)', borderColor: '#FF0000' },
                                                    ]}
                                                    datasets={[
                                                        { data: hook.defectTypes.metricsData.generalSeries.map(e => e.value) },
                                                        { data: hook.defectTypes.metricsData.selectedSeries.map(e => e.value) },
                                                    ]}

                                                    labels={hook.defectTypes.metricsData.seriesNames}
                                                /> :
                                                <Loading size={30} />
                                        }
                                    />
                                </div>
                            </Column>
                        </Column>

                        <Column className="medium">
                            <div className='graphicRadarMapContainer'>
                                <Map selectedMarker={hook.assets.metricsData && hook.assets.metricsData[0]} assets={hook.assets.metricsData} />
                            </div>
                        </Column>
                    </Row>
                </GraphicContainer>
                <Row>
                    <Column className="full">
                        <CardGraphic
                            graphic={
                                !hook.maturity.isLoading && hook.maturity.metricsData.lineYearData ?

                                    <GraphicExpiration
                                        grid={true}
                                        label={'anualDue'}
                                        rows={4}
                                        isArea={true}
                                        legend={[
                                            { color: "#FFAB01", label: "Vencimento" },
                                            { color: "#FF0000", label: "Passivo + Vencimento" },
                                        ]}

                                        config={[
                                            { color: "#FFAB01", width: 1, dialog: true, lineStyle: "solid", label: "anualDue", measure: "m²" },
                                            { color: "#FF0000", width: 1, dialog: true, lineStyle: "solid", label: "anualDuePassive", measure: "m²" },
                                        ]}

                                        data={
                                            hook.maturity.metricsData.lineYearData
                                        }

                                    // data={[
                                    //     { label: "2012", values: [30000, 10000] },
                                    //     { label: "2013", values: [41000, 11000] },
                                    //     { label: "2014", values: [67000, 7000] },
                                    //     { label: "2015", values: [82000, 12000] },
                                    //     { label: "2016", values: [97000, 27000] },
                                    //     { label: "2017", values: [96000, 16000] },
                                    //     { label: "2018", values: [85000, 25000] },
                                    //     { label: "2019", values: [77000, 17000] },
                                    //     { label: "2020", values: [82000, 22000] },
                                    //     { label: "2021", values: [70000, 20000] },
                                    //     { label: "2022", values: [74000, 14000] },
                                    //     { label: "2023", values: [91000, 31000] },
                                    // ]}
                                    /> :
                                    <Loading size={30} />}
                        />
                    </Column>
                </Row>

            </GraphicContainer>
            <GraphicContainer>
                {
                    !hook.assets.isLoading && hook.assets.metricsData?.map(asset => (
                        <Row className="full border">
                            <div className='width-20'>
                                <AssetInformation asset={asset} reports={true} />
                                <ActionsContainer>
                                    {/* <SmallButton>
                                        <img src={plus_icon} alt="Icone de adicionar" />
                                    </SmallButton> */}
                                    <SecondaryButton className='secondary-button' onClick={() => {hook.navigate(`/asset/${asset.id}`) }}>Detalhes do Ativo</SecondaryButton>
                                </ActionsContainer>
                            </div>

                            <div className='width-20'>
                                <CardGraphic
                                    reports={true}
                                    title={`Status do Ativo (m²)`}
                                    footer={{ description: "Área Total", value: asset.areaStatus.totalArea }}
                                    graphic={
                                        <GraphicStatus
                                            isLoading={false}
                                            data={asset.areaStatus} />
                                    }
                                />
                            </div>
                            <div className='width-20'>
                                <CardGraphic
                                    title={"Degradação de RI's (m²)"}
                                    footer={{ description: "Áreas em RI", value: asset.areaStatus.totalDegradedArea }}
                                    graphic={
                                        <GraphicDegradation data={{
                                            total: asset.areaStatus.totalDegradedArea,
                                            ris: asset.areaStatus.degradations.map(e => ({ label: e.label, totalArea: e.totalArea, }))
                                            // ris: [
                                            //     { type: "Ri1", value: 20000 },
                                            //     { type: "Ri2", value: 40000 },
                                            //     { type: "Ri3", value: 50000 },
                                            //     { type: "Ri4", value: 60000 },
                                            //     { type: "Ri5", value: 10000 },
                                            // ]
                                        }} />
                                    }
                                />
                            </div>
                            <div className='width-40'>
                                <CardGraphic
                                    title={"Projeção de Vencimento (m²)"}
                                    graphic={<GraphicExpiration
                                        config={[
                                            { color: "#FF0000", width: 1, dialog: true, lineStyle: "solid", label: `reports${asset.id}` },
                                        ]}

                                        data={asset.dueYears.map(e => ({ label: e.labelYear, values: e.totalArea }))}
                                    // data={[
                                    //     { label: "2024", values: [200000] },
                                    //     { label: "2025", values: [300000] },
                                    //     { label: "2026", values: [150000] },
                                    //     { label: "2027", values: [120000] },
                                    // ]}
                                    />}
                                />
                            </div>
                        </Row>
                    ))
                }

            </GraphicContainer>
        </>
    );
}

export default CardReportAssets;
