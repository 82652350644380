import { Canvas } from "react-three-fiber";
import { FontGreyDarkMediumTitle, FontGreyDarkRegular } from "../../../../utils/typografyApp";
import { ModalMain } from "../styled";
import { OrbitControls, PerspectiveCamera } from "@react-three/drei";
import { useViewSTLModalHook } from "./hook";
import SecondaryButton from "../../SecondaryButton";
import { Loading } from "../../Loading";

const ViewSTLModal = ({ section,  asset3DItemId, onClose }) => {
    const hook = useViewSTLModalHook({ section, asset3DItemId});

    return (
        <ModalMain>
            <div className="menu-content">
                <div className="content content-3d">
                    <div className="title">
                        <FontGreyDarkMediumTitle>STL do 3D {section.name} - {section.tag}</FontGreyDarkMediumTitle>
                    </div>
                    <div className="local_3d">
                        {hook.config3d.isLoading && (
                            <div className="progress-bar">
                                <Loading />
                                <FontGreyDarkRegular><div className="progress-text"> Carregando: {Math.round(hook.config3d.progress)}%</div></FontGreyDarkRegular>
                            </div>
                        )}
                        <Canvas key={section?.id}>
                            <ambientLight intensity={0.5} />
                            <directionalLight
                                position={[20, 10, 10]}
                                castShadow
                                shadow-mapSize-width={1024}
                                shadow-mapSize-height={1024}
                                shadow-camera-far={50}
                                shadow-camera-left={-10}
                                shadow-camera-right={10}
                                shadow-camera-top={10}
                                shadow-camera-bottom={-10}
                            />
                            <PerspectiveCamera ref={hook.config3d.camera} makeDefault position={[-2, 1, 1]} />
                            <OrbitControls ref={hook.config3d.orbitControls} autoRotate={true} autoRotateSpeed={5} />
                            {hook.config3d.objects?.map((object, index) => (
                                object.object ?
                                    <primitive
                                        key={index}
                                        object={object.object}
                                    /> :
                                    <></>
                            ))}
                        </Canvas>
                    </div>
                </div>
                <div className="actions-container">
                    <SecondaryButton onClick={onClose}>Voltar</SecondaryButton>
                </div>
            </div>
        </ModalMain>
    );
}

export default ViewSTLModal;
