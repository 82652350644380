import { InfoWindowCard, InfoWindowContainer, InfoWindowArrow, InfoWindowPage } from "./styled";

const CustomInfoWindow = ({ children, onClose, position, height, width }) => {
    return (
        <>
            <InfoWindowPage onClick={onClose}>
                <InfoWindowContainer onClick={(e) => e.stopPropagation()} height={height} width={width} position={position}>
                    <div className="scroll">
                        <InfoWindowCard height={height} width={width}>
                            {children}
                        </InfoWindowCard>
                    </div>
                </InfoWindowContainer>
                {/* <InfoWindowArrow width={width} position={position} /> */}

            </InfoWindowPage>
        </>

    );
}

export default CustomInfoWindow;