
import { convertNumberBR } from "../../../utils/functions";
import { FontGreyDarkMedium, FontGreyDarkSemiBold } from "../../../utils/typografyApp";
import { CardContainer, GraphicContainer, FooterContainer, CardContent } from "./styled";

const CardGraphic = ({title, height, graphic, footer, sizedSpace = 1, reports=false})=>{
    return(
        <CardContainer >
            {title && <FontGreyDarkMedium className={reports && "title-reports"}>{title}</FontGreyDarkMedium>}
            <CardContent height={height}>
                <GraphicContainer height={height}>
                    {graphic}
                </GraphicContainer>
                {footer && 
                    <FooterContainer className={reports && "footer-reports"}>
                        <FontGreyDarkMedium>{footer.description}</FontGreyDarkMedium>
                        <FontGreyDarkSemiBold>{convertNumberBR(footer.value)}</FontGreyDarkSemiBold>
                    </FooterContainer>
                }
            </CardContent>
        </CardContainer>
    );
}

export default CardGraphic;